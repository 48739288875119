import { svgPathData } from "@fortawesome/pro-solid-svg-icons/faDownload";

import theme from "../../theme/theme";
import { formatNumber } from "../../utils/formatNumber/formatNumber";
import { useWindowDimensions } from "../../utils/windowDimensions/useWindowDimensions";
import { size } from "../../utils/breakpoint/breakpoint";
import { BarChartWithStyled } from "./BarChart.style";

interface ChartProps {
  /**
   * Chart's label
   */
  label: string;
  /**
   * Array of category
   */
  categories: string[];
  /**
   * Array of data
   */
  data: number[];
  /**
   * Set loading state
   */
  loading?: boolean;
  /**
   * Called when bar clicked
   */
  onBarClick?: (category: string, value: number) => void;
}

export const BarChart = (props: ChartProps) => {
  const { categories, label, data, loading, onBarClick } = props;
  const { width } = useWindowDimensions();

  const barChartOption = {
    chart: {
      id: "bar-chart",
      toolbar: {
        tools: {
          download: `<svg height="18px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23343A40" d="${svgPathData}"></path></svg>`,
        },
      },
      events: {
        dataPointMouseEnter: function (event: any) {
          event.path[0].style.cursor = "pointer";
        },
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          onBarClick &&
            onBarClick(
              categories[config.dataPointIndex],
              data[config.dataPointIndex]
            );
        },
      },
    },
    noData: {
      text: loading ? "Loading..." : "No Data Loaded",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.colors.stream.grey1,
        fontSize: "14px",
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          return formatNumber(value);
        },
      },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        columnWidth: "60%",
        dataLabels: {
          position: "top",
        },
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.7,
        },
      },
    },
    colors: [
      function ({
        value,
        seriesIndex,
        dataPointIndex,
      }: {
        value: string;
        seriesIndex: string;
        dataPointIndex: number;
      }) {
        if (dataPointIndex === 6) {
          return theme.colors.ci.primary;
        } else {
          return theme.colors.stream.grey7;
        }
      },
    ],
    dataLabels: {
      style: {
        fontWeight: "normal",
        fontSize: width >= size.sm ? 12 : 10,
        colors: [theme.colors.stream.grey1],
      },
      offsetY: -20,
      formatter: function (value: number) {
        return formatNumber(value);
      },
    },
    legend: {
      showForSingleSeries: true,
      customLegendItems: [
        "Viewers in the past",
        "Viewers on the selected date",
      ],
      markers: {
        fillColors: [theme.colors.stream.grey7, theme.colors.ci.primary],
      },
    },
  };

  return (
    <BarChartWithStyled
      type="bar"
      options={barChartOption}
      height={width >= size.sm ? undefined : 300}
      series={[
        {
          name: label,
          data: data,
        },
      ]}
    />
  );
};
