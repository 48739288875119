import { ReactNode } from "react";

import { ButtonWrapper } from "./Button.style";

export type ButtonType =
  | "primary"
  | "secondary-a"
  | "secondary-b"
  | "secondary-c";

interface ButtonProps {
  /**
   * Button label
   */
  children: ReactNode;
  /**
   * Button's type
   * @default primary
   */
  type?: ButtonType;
  /**
   * Set button to disabled style
   */
  disabled?: boolean;
  /**
   * Set button to have full width
   */
  fullWidth?: boolean;
  /**
   * Set button to use icon label style
   */
  isIconButton?: boolean;
  /**
   * Called when clicked button
   */
  onClick?: () => void;
}

export const Button = (props: ButtonProps) => {
  const { children, disabled, onClick, ...ButtonProps } = props;

  return <ButtonWrapper onClick={()=>{(!disabled && onClick) && onClick()}} disabled={disabled} {...ButtonProps}>{children}</ButtonWrapper>;
};
