import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "react-modern-calendar-datepicker";
import moment from "moment";

import theme from "../../theme/theme";
import {
  DateButtonWrapper,
  HiddenContent,
  DateButton,
  DatePickerOverlay,
} from "./DatePicker.style";

interface DatePickerProps {
  /**
   * Selected date
   */
  selectedDate: Date;
  /**
   * Call when selected date changed
   */
  onChange?: (date: Date) => void;
}

export const CustomDatePicker = (props: DatePickerProps) => {
  const { onChange, selectedDate } = props;
  const [visible, setVisible] = useState(false);

  return (
    <DateButtonWrapper>
      <DateButton onClick={() => setVisible(true)}>
        {moment(selectedDate).format("DD MMM YYYY")}
        <FontAwesomeIcon
          icon={["fas", "calendar-alt"]}
          color={theme.colors.stream.grey2}
        />
      </DateButton>
      <DatePickerOverlay isActive={visible} onClick={() => setVisible(false)} />
      <HiddenContent isActive={visible}>
        <Calendar
          colorPrimary={theme.colors.ci.primary}
          calendarClassName="responsive-calendar"
          value={{
            day: selectedDate.getDate(),
            month: selectedDate.getMonth() + 1,
            year: selectedDate.getFullYear(),
          }}
          onChange={(selectDate) => {
            if (selectDate) {
              setVisible(false);
              const newDate = new Date(
                selectDate.year,
                selectDate.month - 1,
                selectDate.day
              );
              onChange && onChange(newDate);
            }
          }}
        />
      </HiddenContent>
    </DateButtonWrapper>
  );
};
