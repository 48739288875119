import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";

import theme from "../../theme/theme";

const GEO_URL =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const MAP_HEIGHT = 500;
const MAP_WIDTH = 800;

interface WorldMapChartProps {
  /**
   * Array of world map data
   */
  data: { country: string; value: number }[];
}

export const WorldMapChart = (props: WorldMapChartProps) => {
  const { data } = props;

  let max = 0,
    min = 1000000000;

  data.forEach((dataRow) => {
    if (dataRow.value > max) max = dataRow.value;
    if (dataRow.value < min) min = dataRow.value;
  });

  const colorScale = (valueScale: number) => {
    const scaleColor = scaleLinear<string>()
      .domain([min, max])
      .range([theme.colors.primaryMapLight, theme.colors.ci.primary]);
    return scaleColor(valueScale);
  };

  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-5, 10, 0],
        scale: 0,
      }}
      width={MAP_WIDTH}
      height={MAP_HEIGHT}
      style={{ width: "100%", height: "auto" }}
    >
      <ZoomableGroup
        zoom={1}
        translateExtent={[
          [0, 0],
          [MAP_WIDTH, MAP_HEIGHT],
        ]}
      >
        <Geographies geography={GEO_URL}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const d = data.find((s) => s.country === geo.properties.ISO_A2);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d.value) : theme.colors.stream.grey7}
                />
              );
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
};

export default WorldMapChart;
