import styled, { css } from "styled-components";
import { ButtonType } from "./Button";

const primaryStyle = css`
  color: ${(props) => props.theme.colors.stream.white};
  border: 1px solid ${(props) => props.theme.colors.ci.primary};
  background: ${(props) => props.theme.colors.ci.primary};
`;

const secondaryAStyle = css`
  color: ${(props) => props.theme.colors.stream.grey2};
  border: 1px solid ${(props) => props.theme.colors.stream.grey2};
`;

const secondaryBStyle = css`
  color: ${(props) => props.theme.colors.ci.primary};
  border: 1px solid ${(props) => props.theme.colors.ci.primary};
`;

const secondaryCStyle = css`
  color: ${(props) => props.theme.colors.stream.grey2};
  border: 1px solid ${(props) => props.theme.colors.stream.grey8};
  background: ${(props) => props.theme.colors.stream.grey8};
`;

export const ButtonWrapper = styled.div<{
  type?: ButtonType;
  disabled?: boolean;
  fullWidth?: boolean;
  isIconButton?: boolean;
}>`
  font-size: 14px;
  font-weight: 500;
  height: 22px;
  padding: 6px ${(props) => (props.isIconButton ? "10px" : "15px")};
  border-radius: 5px;
  width: ${(props) => (props.fullWidth ? "inherit" : "fit-content")};
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${(props) => {
    if (props.type === "secondary-a") {
      if (props.disabled) {
        return css`
          ${secondaryAStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryAStyle};
          :hover {
            cursor: pointer;
            color: ${(props) => props.theme.colors.stream.white};
            background: ${(props) => props.theme.colors.stream.grey2};
          }
        `;
      }
    } else if (props.type === "secondary-b") {
      if (props.disabled) {
        return css`
          ${secondaryBStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryBStyle};
          :hover {
            cursor: pointer;
            ${primaryStyle}
          }
        `;
      }
    } else if (props.type === "secondary-c") {
      if (props.disabled) {
        return css`
          ${secondaryCStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${secondaryCStyle};
          :hover {
            cursor: pointer;
            background: ${(props) => props.theme.colors.stream.grey6};
            border-color: ${(props) => props.theme.colors.stream.grey6};
          }
        `;
      }
    } else {
      if (props.disabled) {
        return css`
          ${primaryStyle};
          opacity: 0.4;
        `;
      } else {
        return css`
          ${primaryStyle};
          :hover {
            cursor: pointer;
            background: ${(props) => props.theme.colors.ci.middle};
            border-color: ${(props) => props.theme.colors.ci.middle};
          }
        `;
      }
    }
  }}
`;
