import moment from "moment";
import history from "../history/history";

import { MainTabType, SideTabType } from "../../components/container/TabData";

type NavbarButtonType = "Services" | "Admin" | "Usage" | "Logo";

export type defaultUrlParams = {
  teamName: string;
  serviceId: string;
};

export const updateAnalyticUrl = (
  teamName: string,
  serviceId: string,
  mode: string,
  updateDate: {
    selectDate?: Date;
    startTime?: Date;
    endTime?: Date;
  }
) => {
  if (mode === "duration") {
    const ISOStarttime = moment(updateDate.startTime).toISOString();
    const ISOEndTime = moment(updateDate.endTime).toISOString();
    history.push(
      `/${teamName}/services/${serviceId}/analytics/analytic?mode=${mode}&startTime=${ISOStarttime}&endTime=${ISOEndTime}`
    );
  } else {
    const dateFormat = moment(updateDate.selectDate).format("YYYY-MM-DD");
    history.push(
      `/${teamName}/services/${serviceId}/analytics/analytic?mode=${mode}&selectedDate=${dateFormat}`
    );
  }
};

export const updateScheduleManagementUrl = (
  teamName: string,
  serviceId: string,
  selectDate: Date
) => {
  const dateFormat = moment(selectDate).format("YYYY-MM-DD");
  history.push(
    `/${teamName}/services/${serviceId}/schedule/management?selectedDate=${dateFormat}`
  );
};

export const homeRedirect = () => {
  history.push("/");
};

const getUrl = (
  page: MainTabType | SideTabType | NavbarButtonType,
  teamName: string,
  serviceId: string
) => {
  switch (page) {
    case "Analytics":
    case "Viewer Insight":
      return `/${teamName}/services/${serviceId}/analytics/analytic`;
    case "Schedules":
    case "Program":
      return `/${teamName}/services/${serviceId}/schedule/management`;
    // main tab
    case "Overview":
      return `https://fleet.byteark.com/services/${serviceId}`;
    case "Geoblock":
    case "Settings":
      return `https://fleet.byteark.com/services/${serviceId}/${page.toLowerCase()}`;
    case "Security":
      return `https://fleet.byteark.com/services/${serviceId}/settings/security`;
    case "SSL/TLS":
      return `https://fleet.byteark.com/services/${serviceId}/settings/ssl-tls`;
    // analytics side tab
    case "Traffic":
    case "Streaming":
    case "Weekly":
    case "Access":
      return `https://fleet.byteark.com/services/${serviceId}/analytics/${page.toLowerCase()}`;
    case "Content Info":
      return `https://fleet.byteark.com/services/${serviceId}/analytics/content`;
    case "Monthly":
      return `https://fleet.byteark.com/services/${serviceId}/analytics/subscribe`;
    case "Ranking":
      return `https://fleet.byteark.com/services/${serviceId}/analytics/rank`;
    case "Monthly Summary":
      return `https://fleet.byteark.com/services/${serviceId}/usage`;
    // schedules side tab
    case "Report":
    case "Channel":
      return `https://fleet.byteark.com/services/${serviceId}/schedule/${page.toLowerCase()}`;
    // nav bar button
    case "Services":
      return `https://fleet.byteark.com/dashboard/3`;
    case "Admin":
      return `https://fleet.byteark.com/admin`;
    // Logo
    case "Logo":
      return `https://fleet.byteark.com/dashboard/3`;
    default:
      return "/";
  }
};

export default getUrl;
