import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

import { useQueryParams } from "../../utils/queryParams/useQueryParams";
import { getAxiosInstance, getApiConfig } from "../../utils/api/api";
import {
  updateScheduleManagementUrl,
  defaultUrlParams,
} from "../../utils/url/url";
import {
  Button,
  CustomDatePicker,
  SchedulesTable,
  SchedulesModal,
  Container,
} from "../../components";
import { SchedulesTableRowDataType } from "../../components/schedulesTable/SchedulesTable";
import { ModalData } from "../../components/schedulesModal/SchedulesModal";
import { useWindowDimensions } from "../../utils/windowDimensions/useWindowDimensions";
import { size } from "../../utils/breakpoint/breakpoint";
import {
  ContentCard,
  ContentHeaderRow,
  ButtonContainer,
  DatePickerWrapper,
  Title,
} from "./Schedules.style";

interface SchedulesProps {}

export const Schedules = (props: SchedulesProps) => {
  const [modalData, setModalData] = useState<ModalData>();
  const [tableData, setTableData] = useState<SchedulesTableRowDataType[]>([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const query = useQueryParams();
  const backendAPI = getAxiosInstance("default");

  const selectedDate = !!query.get("selectedDate")
    ? moment(query.get("selectedDate")).toDate()
    : new Date();
  const { teamName, serviceId } = useParams<defaultUrlParams>();

  const getTableData = async () => {
    setLoading(true);
    const dateFormat = moment(selectedDate).format("YYYY-MM-DD");
    const apiConfig = getApiConfig();
    const tableResponse = await backendAPI
      .get(
        `/services/${serviceId}/schedule-items/by-date/${dateFormat}`,
        apiConfig
      )
      .catch((error) => console.log("Error in fetching table data: ", error));

    if (tableResponse) {
      const { data } = tableResponse;
      setTableData(data.data);
    }
    setLoading(false);
  };

  const onExportData = async () => {
    const dateFotmat = moment(selectedDate).format("YYYY-MM-DD");
    const apiConfig = getApiConfig("", true);
    const exportDataResponse = await backendAPI
      .get(
        `/services/${serviceId}/schedule-items/export?date=${dateFotmat}`,
        apiConfig
      )
      .catch((error) => console.log("Error in export data: ", error));
    if (exportDataResponse) {
      const url = window.URL.createObjectURL(
        new Blob([exportDataResponse.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `program_${dateFotmat}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const getModalData = async (rowData: any) => {
    setVisible(true);
    setModalLoading(true);
    const programScheduleItemId = rowData._id;
    const modalResponse = await backendAPI
      .get(`/services/${serviceId}/schedule-items/${programScheduleItemId}`)
      .catch((error) => console.log("Error in fetching modal data: ", error));
    if (modalResponse) {
      const { data } = modalResponse.data;
      const initModalData: ModalData = {
        programTitle: data.programTitle,
        episodeName: data.episodeTitle,
        startTime: data.startTime,
        endTime: data.endTime,
        subscribers: data.analytics.totalViewers,
        maxConcurrents: data.analytics.maxConcurrents,
        watchDurationMinutes: data.analytics.totalWatchDurationInMinutes,
        totalViewersByCountry: data.analytics.totalViewersByCountry,
        totalViewersByDevice: data.analytics.totalViewersByDeviceType,
        totalViewersByOs: data.analytics.totalViewersByOS,
        totalViewers: data.analytics.totalViewers,
      };
      setModalData(initModalData);
    }
    setModalLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [history.location]);

  return (
    <Container activeTab="Schedules" activeSideTab="Program">
      <>
        <SchedulesModal
          visible={visible}
          data={modalData}
          loading={modalLoading}
          onClose={() => setVisible(false)}
        />
        <ContentCard>
          <ContentHeaderRow>
            <Title>Program Management</Title>
            <ButtonContainer>
              {width >= size.sm ? (
                <Button type="secondary-a" onClick={() => onExportData()}>
                  <span>
                    <FontAwesomeIcon icon={["fas", "download"]} /> Export
                  </span>
                </Button>
              ) : (
                <Button type="secondary-a" isIconButton>
                  <FontAwesomeIcon icon={["fas", "download"]} />
                </Button>
              )}
              <DatePickerWrapper>
                <CustomDatePicker
                  selectedDate={selectedDate}
                  onChange={(selectDate) =>
                    updateScheduleManagementUrl(teamName, serviceId, selectDate)
                  }
                />
              </DatePickerWrapper>
            </ButtonContainer>
          </ContentHeaderRow>
          <SchedulesTable
            data={tableData}
            loading={loading}
            onRowClick={(data) => getModalData(data)}
          />
        </ContentCard>
      </>
    </Container>
  );
};
