import styled from "styled-components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${(props) => props.theme.colors.stream.lightBlue};
`;

export const HeaderContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.stream.navy};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  max-width: 1180px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 30px 0;

  @media ${maxLayout.lg} {
    padding: 16px;
  }

  @media ${maxLayout.md} {
    flex-direction: column;
  }
`;

export const SideTabWrapper = styled.div`
  width: 194px;
  margin-right: 16px;

  @media ${maxLayout.lg} {
    display: none;
    width: 0px;
    height: 0px;
  }
`;

export const ContentCardWrapper = styled.div`
  flex: 1;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.stream.white};
`;

export const ServiceLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1rem 0 1.5rem;

  @media ${maxLayout.md} {
    margin: 0.75rem 1rem 0.15rem;
  }

  @media ${maxLayout.sm} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
`;

export const ServiceLabel = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.stream.white};

  @media ${maxLayout.sm} {
    font-size: 20px;
    padding-bottom: 6px;
  }
`;

export const ServiceUrl = styled.span`
  font-size: 14px;
  font-style: italic;
  color: ${(props) => props.theme.colors.stream.grey3};
  margin-left: 20px;

  @media ${maxLayout.sm} {
    margin-left: 0;
  }
`;
