import { useEffect, useState } from "react";
import { Route, useLocation } from "react-router-dom";

import { getAxiosInstance, getApiConfig } from "../utils/api/api";

interface ProtectedRouteProps {
  component?: React.FC;
  path?: string;
  exact?: boolean;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component, path, exact = false } = props;
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const teamName = useLocation().pathname.split("/")[1];
  const backendAPI = getAxiosInstance("default");

  const isAuthenticated = async () => {
    const apiConfig = getApiConfig(teamName);
    const userResponse = await backendAPI
      .get("me", apiConfig)
      .catch((err) => console.log("Error in getting user data: ", err));

    if (userResponse) {
      setUser(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    isAuthenticated();
  }, [teamName]);

  return loading ? (
    <></>
  ) : teamName && user ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Route render={() => (window.location.href = `/accounts/byteark/`)} />
  );
};

export default ProtectedRoute;
