import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getIconDevice = (deviceName: string) => {
  const lowerCaseDeviceName = deviceName.toLowerCase();

  if (lowerCaseDeviceName === "unknown") {
    return <FontAwesomeIcon icon={["fas", "question-circle"]} />;
  }

  if (
    lowerCaseDeviceName.includes("windows") ||
    lowerCaseDeviceName.includes("mac")
  ) {
    return <FontAwesomeIcon icon={["fas", "desktop-alt"]} />;
  } else if (lowerCaseDeviceName.includes("phone")) {
    return <FontAwesomeIcon icon={["fas", "mobile-alt"]} />;
  } else if (
    lowerCaseDeviceName.includes("tv") ||
    lowerCaseDeviceName.includes("chromecast")
  ) {
    return <FontAwesomeIcon icon={["fas", "tv-alt"]} />;
  } else if (
    lowerCaseDeviceName.includes("tablet") ||
    lowerCaseDeviceName.includes("ipad")
  ) {
    return <FontAwesomeIcon icon={["fas", "tablet-alt"]} />;
  } else if (lowerCaseDeviceName.includes("other")) {
    return <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />;
  } else {
    return <FontAwesomeIcon icon={["far", "phone-laptop"]} />;
  }
};
