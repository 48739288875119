import styled from "styled-components";

export const IconWrapper = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.stream.grey1};

  > :first-child {
    width: 25px;
    min-width: 25px;
  }
`;

export const IconLabel = styled.div`
  padding-left: 4px;
  font-size: 14px;
`;
