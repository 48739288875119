import styled, { css } from "styled-components";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const DateButtonWrapper = styled.div`
  position: relative;
  flex: 1;
  :hover {
    cursor: pointer;
  }

  .Calendar__day.-ltr {
    min-height: 2.7em;
    font-size: 1.45em;
  }

  .Calendar__weekDay{
    color: ${props => props.theme.colors.stream.grey2};
  }

  @media ${maxLayout.sm} {
    min-width: 0;
    .Calendar__day.-ltr {
      min-height: 2.8em;
    }
  }

  @media ${maxLayout.xxs} {
    .Calendar__day.-ltr {
      min-height: 2.5em;
    }
  }
`;

export const DateButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding: 9px;
  border: 1px solid ${(props) => props.theme.colors.stream.grey2};
  border-radius: 5px;
  color: ${(props) => props.theme.colors.stream.grey2};
`;

export const HiddenContent = styled.div<{ isActive?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 13;
  width: 351px;
  border-radius: 5px;
  right: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.theme.colors.stream.white};
  border: 1px solid ${(props) => props.theme.colors.stream.grey7};
  ${(props) => {
    if (props.isActive) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateY(8px);
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s ease-out;
        transform: translateY(10px);
        visibility: hidden;
      `;
    }
  }};
  .responsive-calendar {
    width: 100%;
    box-shadow: none;
    border-radius: none;
  }

  @media ${maxLayout.sm} {
    width: calc(100% + 88px);
  }
`;

export const DatePickerOverlay = styled.div<{ isActive?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: ${(props) => (props.isActive ? 11 : -10)};
`;
