import styled, { css } from "styled-components";

export const TabsContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

export const TabTitleWrapper = styled.div<{ active?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  color: ${(props) => props.theme.colors.stream.grey2};

  ${(props) => {
    if (props.active) {
      return css`
        border-radius: 5px 5px 0 0;
        color: ${props.theme.colors.stream.grey1};
        background: ${props.theme.colors.stream.grey7};
      `;
    }
  }}
`;

export const TabContentWrapper = styled.div`
  border-top: 3px solid ${(props) => props.theme.colors.stream.grey7};
  padding: 14px 0;
`;
