import { Route, Switch } from "react-router-dom";
import { Router } from "react-router";

import history from "./utils/history/history";
import ProtectedRoute from "./middlewares/ProtectedRoute";
import { PageNotFound, Analytics, Schedules, Dashboard } from "./pages";

const RouterClass = () => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (window.location.href = `/accounts/byteark/`)}
      />
      {/** Protected route */}
      <ProtectedRoute exact path="/:teamName" component={Dashboard} />
      <ProtectedRoute
        path="/:teamName/services/:serviceId/analytics/analytic"
        component={Analytics}
      />
      <ProtectedRoute
        path="/:teamName/services/:serviceId/schedule/management"
        component={Schedules}
      />
      {/** Page not found */}
      <Route component={PageNotFound} />
    </Switch>
  </Router>
);

export default RouterClass;
