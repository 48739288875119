import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import {
  Table,
  TableHeader,
  HeaderWrapper,
  TableContent,
  TableRow,
  ProgramWrapper,
  MobileCard,
  MobileTime,
  MobileProgramName,
  NumberContainer,
  NumberWrapper,
  NumberLabel,
  NumberValue,
  NoDataCard,
  NoDataLabel,
  IconWrapper,
  ProgramNameHeader,
} from "./SchedulesTable.style";
import { size } from "../../utils/breakpoint/breakpoint";
import { useWindowDimensions } from "../../utils/windowDimensions/useWindowDimensions";
import { formatNumber } from "../../utils/formatNumber/formatNumber";

export type SchedulesTableRowDataType = {
  startTime: any;
  endTime: any;
  programTag: any;
  programTitle: string;
  episodeName: string;
  aggregations: any;
  onAirDate: any;
  analytics: {
    maxConcurrents: number;
    scheduleId: string;
    scheduleItemId: string;
    serviceId: string;
    totalViewers: number;
    totalViewersByCountry: number;
    totalViewersByDeviceType: number;
    totalWatchDurationInMinutes: number;
  };
};

interface SchedulesTableProps {
  /**
   * Schedules' data
   * */
  data: SchedulesTableRowDataType[];
  /**
   * Set loading state
   */
  loading: boolean;
  /**
   * Call when table row clicked
   */
  onRowClick?: (rowData: SchedulesTableRowDataType) => void;
}

export const SchedulesTable = (props: SchedulesTableProps) => {
  const { data, loading, onRowClick } = props;

  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState("none");
  const [sortFrom, setSortFrom] = useState("none");

  const { width } = useWindowDimensions();

  const sortData = (
    key:
      | "startTime"
      | "endTime"
      | "programTag"
      | "programTitle"
      | "analytics"
      | "maxConcurrents"
      | "totalViewers"
      | "totalWatchDurationInMinutes"
  ) => {
    let sortDataFromTag = sortFrom;
    let newSortData = [...data];
    if (key !== sortBy) {
      setSortBy(key);
      sortDataFromTag = "none";
    }
    if (sortDataFromTag === "none") {
      setSortFrom("ascend");
      if (
        key === "maxConcurrents" ||
        key === "totalViewers" ||
        key === "totalWatchDurationInMinutes"
      ) {
        newSortData = newSortData.sort((program, program2) =>
          program.analytics[key] > program2.analytics[key]
            ? 1
            : program.analytics[key] < program2.analytics[key]
            ? -1
            : 0
        );
      } else {
        newSortData = newSortData.sort((program, program2) =>
          program[key] > program2[key]
            ? 1
            : program[key] < program2[key]
            ? -1
            : 0
        );
      }
    } else if (sortDataFromTag === "ascend") {
      setSortFrom("descend");
      if (
        key === "maxConcurrents" ||
        key === "totalViewers" ||
        key === "totalWatchDurationInMinutes"
      ) {
        newSortData = newSortData.sort((program, program2) =>
          program.analytics[key] < program2.analytics[key]
            ? 1
            : program.analytics[key] > program2.analytics[key]
            ? -1
            : 0
        );
      } else {
        newSortData = newSortData.sort((program, program2) =>
          program[key] < program2[key]
            ? 1
            : program[key] > program2[key]
            ? -1
            : 0
        );
      }
    } else {
      setSortFrom("none");
    }
    setSortedData(newSortData);
  };

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  return (
    <>
      {width >= size.md ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>
                <HeaderWrapper>
                  Time
                  <IconWrapper>
                    <FontAwesomeIcon
                      icon={
                        sortBy !== "startTime" || sortFrom === "none"
                          ? ["fad", "sort"]
                          : sortFrom === "ascend"
                          ? ["fad", "sort-down"]
                          : ["fad", "sort-up"]
                      }
                      onClick={() => {
                        sortData("startTime");
                      }}
                    />
                  </IconWrapper>
                </HeaderWrapper>
              </TableHeader>
              <ProgramNameHeader>
                <HeaderWrapper>
                  Program Name
                  <IconWrapper>
                    <FontAwesomeIcon
                      icon={
                        sortBy !== "programTitle" || sortFrom === "none"
                          ? ["fad", "sort"]
                          : sortFrom === "ascend"
                          ? ["fad", "sort-down"]
                          : ["fad", "sort-up"]
                      }
                      onClick={() => {
                        sortData("programTitle");
                      }}
                    />
                  </IconWrapper>
                </HeaderWrapper>
              </ProgramNameHeader>
              <TableHeader>
                <HeaderWrapper isNumber>
                  Subscribers
                  <IconWrapper>
                    <FontAwesomeIcon
                      icon={
                        sortBy !== "totalViewers" || sortFrom === "none"
                          ? ["fad", "sort"]
                          : sortFrom === "ascend"
                          ? ["fad", "sort-down"]
                          : ["fad", "sort-up"]
                      }
                      onClick={() => {
                        sortData("totalViewers");
                      }}
                    />
                  </IconWrapper>
                </HeaderWrapper>
              </TableHeader>
              <TableHeader>
                <HeaderWrapper isNumber>
                  Max Concurrents
                  <IconWrapper>
                    <FontAwesomeIcon
                      icon={
                        sortBy !== "maxConcurrents" || sortFrom === "none"
                          ? ["fad", "sort"]
                          : sortFrom === "ascend"
                          ? ["fad", "sort-down"]
                          : ["fad", "sort-up"]
                      }
                      onClick={() => {
                        sortData("maxConcurrents");
                      }}
                    />
                  </IconWrapper>
                </HeaderWrapper>
              </TableHeader>
              <TableHeader>
                <HeaderWrapper isNumber>
                  Watch Duration Minutes
                  <IconWrapper>
                    <FontAwesomeIcon
                      icon={
                        sortBy !== "totalWatchDurationInMinutes" ||
                        sortFrom === "none"
                          ? ["fad", "sort"]
                          : sortFrom === "ascend"
                          ? ["fad", "sort-down"]
                          : ["fad", "sort-up"]
                      }
                      onClick={() => {
                        sortData("totalWatchDurationInMinutes");
                      }}
                    />
                  </IconWrapper>
                </HeaderWrapper>
              </TableHeader>
            </tr>
          </thead>
          {sortedData.length !== 0 && (
            <tbody>
              {sortedData.map((rowData, index) => {
                return [
                  <TableRow
                    onClick={() => {
                      onRowClick && onRowClick(rowData);
                    }}
                    key={`schedules-row-${index}`}
                  >
                    <TableContent isFade>
                      {moment(rowData.startTime).format("HH:mm")} -{" "}
                      {moment(rowData.endTime).format("HH:mm")}
                    </TableContent>
                    <TableContent>
                      <ProgramWrapper>{rowData.programTitle}</ProgramWrapper>
                    </TableContent>
                    <TableContent isNumber>
                      {formatNumber(rowData.analytics.totalViewers)}
                    </TableContent>
                    <TableContent isNumber>
                      {formatNumber(rowData.analytics.maxConcurrents)}
                    </TableContent>
                    <TableContent isNumber>
                      {formatNumber(
                        rowData.analytics.totalWatchDurationInMinutes
                      )}
                    </TableContent>
                  </TableRow>,
                ];
              })}
            </tbody>
          )}
        </Table>
      ) : (
        <>
          {data.map((rowData, index) => (
            <MobileCard
              key={`schedules-card-${index}`}
              onClick={() => {
                onRowClick && onRowClick(rowData);
              }}
            >
              <MobileTime>
                {moment(rowData.startTime).format("HH:mm")} -{" "}
                {moment(rowData.endTime).format("HH:mm")}
              </MobileTime>
              <MobileProgramName>{rowData.programTitle}</MobileProgramName>
              <NumberContainer>
                <NumberWrapper>
                  <NumberValue>
                    {formatNumber(rowData.analytics.totalWatchDurationInMinutes)}
                  </NumberValue>
                  <NumberLabel>Watch Duration Minutes</NumberLabel>
                </NumberWrapper>
                <NumberWrapper>
                  <NumberValue>
                    {formatNumber(rowData.analytics.maxConcurrents)}
                  </NumberValue>
                  <NumberLabel>Max Concurrents</NumberLabel>
                </NumberWrapper>
                <NumberWrapper>
                  <NumberValue>
                    {formatNumber(rowData.analytics.totalViewers)}
                  </NumberValue>
                  <NumberLabel>Subscribers</NumberLabel>
                </NumberWrapper>
              </NumberContainer>
            </MobileCard>
          ))}
        </>
      )}
      {(data.length === 0 || !data) && (
        <NoDataCard>
          {loading ? (
            <FontAwesomeIcon icon={["fas", "spinner"]} size="6x" pulse />
          ) : (
            <>
              <FontAwesomeIcon icon={["fas", "exclamation"]} size="6x" />
              <NoDataLabel>No Data</NoDataLabel>
            </>
          )}
        </NoDataCard>
      )}
    </>
  );
};
