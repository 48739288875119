import ApexChart from "react-apexcharts";

import theme from "../../theme/theme";
import { formatNumber } from "../../utils/formatNumber/formatNumber";

interface ChartProps {
  /**
   * Array of category
   */
  categories: string[];
  /**
   * Array of data
   */
  data: number[];
  /**
   * Set loading state
   */
  loading?: boolean;
}

export const DonutChart = (props: ChartProps) => {
  const { categories, data, loading } = props;

  const donutChartOptions = {
    chart: {
      id: "donut-chart",
      width: 500,
      type: "donut" as any,
      redrawOnParentResize: true,
      events: {
        dataPointMouseEnter: function (event: any) {
          event.path[0].style.cursor = "pointer";
        },
      },
    },
    noData: {
      text: loading ? "Loading..." : "No Data Loaded",
      align: "center" as any,
      verticalAlign: "middle" as any,
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.colors.stream.grey1,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          return formatNumber(value);
        },
      },
    },
    colors: Object.values(theme.colors.graph),
    stroke: {
      show: false,
    },
    plotOptions: {
      legend: {
        show: true,
        position: "bottom",
      },
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              color: theme.colors.stream.grey1,
            },
            value: {
              show: true,
              formatter: function (value: string) {
                return formatNumber(parseInt(value));
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enable: true,
      style: {
        fontSize: "12px",
      },
    },
    labels: categories,
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            redrawOnParentResize: true,
          },
          width: "100%",
          height: "auto",
          labels: categories,
          legend: {
            show: false,
          },
          dataLabels: {
            enable: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            legend: {
              show: true,
              position: "bottom",
            },
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                  },
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 769,
        options: {
          chart: {
            redrawOnParentResize: true,
          },
          width: "100%",
          height: "auto",
          labels: categories,
          legend: {
            position: "bottom" as any,
            fontSize: "10px",
            marker: {
              width: 8,
              height: 8,
              radius: 8,
            },
          },
          dataLabels: {
            enable: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            legend: {
              show: true,
              position: "bottom",
            },
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "12px",
                  },
                },
              },
            },
          },
        },
      },
    ],
  };

  return <ApexChart type="donut" options={donutChartOptions} series={data} />;
};
