const theme = {
  colors: {
    ci: {
      primary: "#00A6ED",
      secondary: "#5613B5",
      secondary2: "#6957C5",
      middle: "#2D83DB",
      primaryLighten: "#62BFF4",
      secondaryLighten: "#735DE5",
      primaryDarken: "#006692",
      secondaryDarken: "#443880",
      primaryOverlay: "rgba(0, 166, 237, 0.1)",
    },
    stream: {
      grey1: "#343A40",
      grey2: "#6C757D",
      grey3: "#9EA4A9",
      grey4: "#B1B9C0",
      grey5: "#D0D4D8",
      grey6: "#E8EAEE",
      grey7: "#EEF0F2",
      grey8: "#F8F9FA",
      grey9: "#FBFBFC",
      white: "#FFFFFF",
      overlay: "#253742",
      overlayHover: "#465562",
      navy: "#212D37",
      navyDarken: "#1A242C",
      lightBlue: "#F0F5F8",
    },
    status: {
      red: "#EB5757",
      green: "#3FB772",
      yellow: "#F0B542",
    },
    graph: {
      graph1: "#2F4858",
      graph2: "#33658A",
      graph3: "#9DD3FA",
      graph4: "#58C2B6",
      graph5: "#FFE177",
      graph6: "#F48F46",
      graph7: "#C53C3A",
      graph8: "#DADADA",
    },
    primaryMapLight: "#94DFFF",
  },
};

export default theme;
