import styled from "styled-components";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
`;

export const TableHeader = styled.td`
  margin: 0;
  padding: 9px;
  max-width: 115px;
  width: 115px;
  border-bottom: 1px solid ${(props) => props.theme.colors.stream.grey8};
  background: ${(props) => props.theme.colors.stream.grey8};

  @media ${maxLayout.md} {
    padding: 6px;
  }
`;

export const ProgramNameHeader = styled.td`
  margin: 0;
  padding: 9px;
  border-bottom: 1px solid ${(props) => props.theme.colors.stream.grey8};
  background: ${(props) => props.theme.colors.stream.grey8};
`;

export const HeaderWrapper = styled.div<{ isNumber?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey1};
  text-align: ${(props) => (props.isNumber ? "right" : "left")};
  justify-content: ${(props) =>
    props.isNumber ? "flex-end" : "space-between"};
`;

export const TableContent = styled.td<{ isNumber?: boolean; isFade?: boolean }>`
  margin: 0;
  padding: ${(props) => (props.isNumber ? "16px 25px 16px 9px" : "16px 9px")};
  font-size: 16px;
  color: ${(props) =>
    props.isFade
      ? props.theme.colors.stream.grey3
      : props.theme.colors.stream.grey1};
  border-bottom: 1px solid ${(props) => props.theme.colors.stream.grey6};
  text-align: ${(props) => (props.isNumber ? "right" : "left")};
`;

export const TableRow = styled.tr`
  :hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.stream.grey9};
  }
`;

export const ProgramWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProgramTag = styled.div`
  padding: 5px 8px;
  font-size: 12px;
  margin: 0 6px 0 0;
  border-radius: 3px;
  display: block;
  background: ${(props) => props.theme.colors.stream.grey2};
  color: ${(props) => props.theme.colors.stream.grey9};
`;

// Schedules mobile card

export const MobileCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.stream.grey5};
  }
`;

export const MobileTime = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.stream.grey3};
`;

export const MobileProgramName = styled.span`
  max-width: 100%;
  line-height: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${(props) => props.theme.colors.stream.grey1};
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
`;

export const NumberWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  :not(:first-child)&&:not(:last-child) {
    padding: 0 16px;
  }
`;

export const NumberLabel = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.colors.stream.grey3};
  padding-top: 4px;
`;

export const NumberValue = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey1};
`;

export const NoDataCard = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.stream.grey2};
`;

export const NoDataLabel = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 16px;
  text-align: center;
`;

export const IconWrapper = styled.div`
  color: ${(props) => props.theme.colors.stream.grey2};
  margin-left: 8px;
  :hover {
    cursor: pointer;
  }
`;
