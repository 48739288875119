import styled from "styled-components";
import ApexChart from "react-apexcharts";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const BarChartWithStyled = styled(ApexChart)`
  .apexcharts-toolbar {
    top: -20px !important;
    right: 15px !important;
  }
  .apexcharts-menu-icon > svg {
    overflow: visible;
  }
  .apexcharts-menu-item {
    display: flex;
    justify-content: center;
  }

  @media ${maxLayout.sm} {
    .apexcharts-toolbar {
      top: -17px !important;
      right: 13px !important;
    }
  }
`;
