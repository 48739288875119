import styled, { css } from "styled-components";

export const SelectButton = styled.div<{ borderRadius?: string }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 9px;
  color: ${(props) => props.theme.colors.stream.grey2};
  border: 1px solid ${(props) => props.theme.colors.stream.grey2};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "3px"};
  :hover {
    cursor: pointer;
  }
`;

export const SelectedValueLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectOverlay = styled.div<{ isActive?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: ${(props) => (props.isActive ? 12 : -10)};
`;

export const OptionsContainer = styled.div<{ isActive?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 13;
  border-radius: 3px;
  padding: 1px;
  width: 100%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  background: ${(props) => props.theme.colors.stream.white};
  border: 1px solid ${(props) => props.theme.colors.stream.grey7};
  ${(props) => {
    if (props.isActive) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateY(8px);
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s ease-out;
        transform: translateY(10px);
        visibility: hidden;
      `;
    }
  }}
`;

export const Option = styled.div<{ isSelected?: boolean }>`
  font-size: 14px;
  padding: 3px 10px;
  height: 27px;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isSelected
      ? props.theme.colors.stream.grey1
      : props.theme.colors.stream.grey2};
  background: ${(props) =>
    props.isSelected
      ? props.theme.colors.stream.grey6
      : props.theme.colors.stream.white};
  :hover {
    background: ${(props) => props.theme.colors.stream.grey8};
    cursor: pointer;
  }
`;
