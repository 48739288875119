import styled from "styled-components";

import { Link } from "../link/Link";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const SideTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.stream.white};

  > div:first-child {
    border-radius: 5px 5px 0 0;
  }
  > div:last-child {
    border-radius: 0 0 5px 5px;
  }

  @media ${maxLayout.md} {
    display: none;
  }
`;

export const SideTabWithStyled = styled(Link)<{
  isActive?: boolean;
  canClick?: boolean;
  isMainTab?: boolean;
}>`
  display: grid;
  column-gap: 6px;
  text-decoration: none;
  padding: 16px ${(props) => (props.isMainTab ? "16px" : "36px")};
  font-weight: 500;
  font-size: ${(props) => (props.isMainTab ? "16px" : "14px")};
  color: ${(props) =>
    props.isMainTab || props.isActive
      ? props.theme.colors.stream.grey1
      : props.theme.colors.stream.grey3};
  border-left: 4px solid
    ${(props) =>
      props.isActive ? props.theme.colors.ci.primary : "transparent"};
  background: ${(props) =>
    props.isActive ? props.theme.colors.ci.primaryOverlay : "transparent"};
  :hover {
    cursor: ${(props) => (props.canClick ? "pointer" : "normal")};
    background: ${(props) =>
      props.canClick ? props.theme.colors.ci.primaryOverlay : "transparent"};
  }
`;

export const LabelWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;
