import styled, { css } from "styled-components";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 340px;

  @media ${maxLayout.md} {
    width: 100%;
  }
`;

export const DateButtonWrapper = styled.div`
  position: relative;
  flex: 1;
  min-width: 234px;
  :hover {
    cursor: pointer;
  }
  .Calendar__day.-ltr {
    min-height: 2.7em;
    font-size: 1.45em;
  }

  .Calendar__weekDay {
    color: ${(props) => props.theme.colors.stream.grey2};
  }

  @media ${maxLayout.sm} {
    min-width: 0;
    .Calendar__day.-ltr {
      min-height: 2.8em;
    }
  }

  @media ${maxLayout.xxs} {
    .Calendar__day.-ltr {
      min-height: 2.5em;
    }
  }
`;

export const SelectButtonWrapper = styled.div`
  position: relative;
  width: 90px;
  border-radius: 3px 0 0 3px;
  :hover {
    cursor: pointer;
  }
`;

export const DateButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding: 9px;
  border: 1px solid ${(props) => props.theme.colors.stream.grey2};
  border-left: transparent;
  border-radius: 0 3px 3px 0;
  color: ${(props) => props.theme.colors.stream.grey2};
`;

export const HiddenContent = styled.div<{ isActive?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 13;
  width: calc(100% + 90px);
  max-width: 351px;
  border-radius: 5px;
  right: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  background: ${(props) => props.theme.colors.stream.white};
  border: 1px solid ${(props) => props.theme.colors.stream.grey7};
  ${(props) => {
    if (props.isActive) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateY(8px);
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s ease-out;
        transform: translateY(10px);
        visibility: hidden;
      `;
    }
  }};

  .responsive-calendar {
    width: 100%;
    box-shadow: none;
    border-radius: none;
  }

  @media ${maxLayout.sm} {
    max-width: unset;
  }
`;

export const Hint = styled.p`
  font-size: 14px;
  margin: 0 8px 8px;
  color: ${(props) => props.theme.colors.stream.grey4};
`;

export const SelectedContainer = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 8px;
`;

export const Label = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.stream.grey1};
  display: flex;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 156px;
  :hover {
    cursor: pointer;
  }
`;

export const MonthPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const DurationPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div:last-child > :last-child {
    margin-top: 24px;
  }
`;

export const TimeLabel = styled(Label)`
  margin: 0 15px 0 41px;

  @media ${maxLayout.sm} {
    margin: 0 14px 0 44px;
  }
`;

export const DurationLabel = styled(Label)`
  margin-bottom: 16px;
`;

export const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CalendarExtraContent = styled.div`
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
`;

export const DatePickerOverlay = styled.div<{ isActive?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: ${(props) => (props.isActive ? 11 : -10)};
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 20px;

  @media ${maxLayout.sm} {
    width: 100%;
    height: auto;

    > :last-child {
      margin-left: 8px;
    }
  }
`;

export const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 8px;
  > :not(:first-child) {
    margin-left: 8px;
  }

  @media ${maxLayout.sm} {
    margin-left: 0;
    margin-top: 8px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  align-self: flex-start;
  color: ${(props) => props.theme.colors.stream.grey4};

  @media ${maxLayout.sm} {
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }
`;
