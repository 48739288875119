import styled from "styled-components";
import TimePicker from "rc-time-picker";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const TimepickerWrapper = styled(TimePicker)`
  .rc-time-picker-input {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    width: 105px;
    height: 32px;
    border: 1px solid ${(props) => props.theme.colors.stream.grey2};
    border-radius: 3px;
    color: ${(props) => props.theme.colors.stream.grey2};
    background: ${(props) => props.theme.colors.stream.white};
    padding: 4px 9px;

    @media ${maxLayout.sm} {
      width: 90px;
    }
  }
`;

export const InputIconWrapper = styled.span`
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  margin: 0 -6px;
  background: ${(props) => props.theme.colors.stream.grey2};
  padding: 7px 9px;
  border-radius: 0 3px 3px 0;
  color: ${(props) => props.theme.colors.stream.white};
`;

export const ClearIconWrapper = styled.span`
  position: absolute;
  right: 9px;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
  padding: 9px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.stream.grey5};
`;
