import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, Fragment } from "react";

import getUrl, { homeRedirect } from "../../utils/url/url";
import FleetLogoSrc from "../../assets/logo.svg";
import { getApiConfig, getAxiosInstance } from "../../utils/api/api";
import { Dropdown } from "../dropdown/Dropdown";
import { MainTabType, SideTabType, MainTab } from "../container/TabData";
import {
  NavBarContainer,
  FleetLogo,
  FleetLogoContainer,
  ButtonGroup,
  ButtonNav,
  ProfileImage,
  ProfileLabel,
  IconWrapper,
  HiddenSideBar,
  SidebarButton,
  SidebarHeader,
  ProfileDropdownWrapper,
  SidebarButtonGroup,
  SidebarServiceLabel,
  SidebarServiceUrl,
  SidebarTabContainer,
  HiddenContainer,
  TabContainer,
  SidebarOverLay,
  SidebarServiceContainer,
  SideTabProfileContainer,
  SideTabProfileWWrapper,
  SidebarProfileTab,
} from "./NavBar.style";

interface NavBarProps {
  /**
   * Array of tab
   */
  tabs: MainTab[];
  /**
   * User's label
   */
  user: string;
  /**
   * service's id
   */
  serviceId: string;
  /**
   * team name
   */
  teamName: string;
  /**
   * Set if current user is admin
   */
  isAdmin?: boolean;
  /**
   * Service label
   */
  serviceLabel?: string;
  /**
   * Service url
   */
  serviceUrl?: string;
  /**
   * service's group id
   */
  groupId?: number;
  /**
   * Set active state in nav bar
   */
  activeState?: string;
  /**
   * Set active tab
   */
  activeTab?: MainTabType;
  /**
   * Set active  side tab
   */
  activeSideTab?: SideTabType;
}

export const NavBar = (props: NavBarProps) => {
  const {
    activeState,
    groupId,
    activeSideTab,
    activeTab,
    user,
    tabs,
    serviceLabel,
    serviceUrl,
    teamName,
    serviceId,
    isAdmin,
  } = props;

  const [visible, setVisible] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const [hiddenVisibleArray, setHiddenVisibleArray] = useState<Array<string>>(
    []
  );
  const logoutAPI = getAxiosInstance("logout");

  const PROFILE_OPTIONS = [
    {
      label: "My teams",
      icon: <FontAwesomeIcon icon={["fas", "users"]} />,
      onClick: () => {
        window.location.href = `https://accounts.byteark.com/${teamName}/dashboard`;
      },
    },
    {
      label: "My services",
      icon: <FontAwesomeIcon icon={["fas", "user-cog"]} />,
      onClick: () => {
        window.location.href = `https://fleet.byteark.com/dashboard/3`;
      },
    },
    {
      label: "Logout",
      icon: <FontAwesomeIcon icon={["fas", "sign-out-alt"]} />,
      onClick: (teamName: string) => {
        const apiConfig = getApiConfig(teamName);
        logoutAPI
          .post("/accounts/logout", {}, apiConfig)
          .then((re) => {
            homeRedirect();
          })
          .catch((err) => console.log("Error in getting user data: ", err));
      },
    },
  ];

  const checkVisible = (label: string) => {
    const newArray = [...hiddenVisibleArray];
    const index = newArray.indexOf(label);
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(label);
    }
    setHiddenVisibleArray(newArray);
  };

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [visible]);

  return (
    <NavBarContainer>
      <SidebarOverLay isActive={visible} onClick={() => setVisible(false)} />
      <HiddenSideBar isActive={visible}>
        <SidebarHeader>
          <FleetLogoContainer to={getUrl("Logo", teamName, serviceId)}>
            <FleetLogo src={FleetLogoSrc} />
            FLEET
          </FleetLogoContainer>
          <FontAwesomeIcon
            icon={["fas", "times"]}
            onClick={() => setVisible(false)}
            size="lg"
          />
        </SidebarHeader>
        <SidebarButtonGroup>
          <SidebarButton to={getUrl("Services", teamName, serviceId)}>
            <FontAwesomeIcon icon={["fas", "tools"]} size="2x" />
            SERVICES
          </SidebarButton>
          {isAdmin && (
            <SidebarButton to={getUrl("Admin", teamName, serviceId)}>
              <FontAwesomeIcon icon={["fas", "user-tie"]} size="2x" />
              ADMIN
            </SidebarButton>
          )}
        </SidebarButtonGroup>
        <SidebarServiceContainer>
          <SidebarServiceLabel>{serviceLabel}</SidebarServiceLabel>
          <SidebarServiceUrl>{serviceUrl}</SidebarServiceUrl>
        </SidebarServiceContainer>
        <SidebarTabContainer>
          {tabs.map((mainTab) => (
            <Fragment key={`main-${mainTab.label}`}>
              <TabContainer
                to={getUrl(mainTab.label, teamName, serviceId)}
                type={"main" as any}
                onClick={
                  mainTab.sideTabs
                    ? () => mainTab.sideTabs && checkVisible(mainTab.label)
                    : undefined
                }
                isActive={activeTab === mainTab.label}
              >
                {mainTab.label}

                {mainTab.sideTabs && (
                  <FontAwesomeIcon
                    icon={
                      hiddenVisibleArray.includes(mainTab.label)
                        ? ["fas", "minus"]
                        : ["fas", "plus"]
                    }
                    size="xs"
                  />
                )}
              </TabContainer>
              <HiddenContainer
                isActive={hiddenVisibleArray.includes(mainTab.label)}
              >
                {mainTab.sideTabs?.map((sideTab) => (
                  <Fragment key={`side-${sideTab.label}`}>
                    <TabContainer
                      to={getUrl(sideTab.label, teamName, serviceId)}
                      type={"side" as any}
                      onClick={
                        sideTab.subTabs
                          ? () => sideTab.subTabs && checkVisible(sideTab.label)
                          : undefined
                      }
                      isActive={activeSideTab === sideTab.label}
                    >
                      {sideTab.label}
                      {sideTab.subTabs && (
                        <FontAwesomeIcon
                          icon={
                            hiddenVisibleArray.includes(sideTab.label)
                              ? ["far", "angle-up"]
                              : ["far", "angle-down"]
                          }
                        />
                      )}
                    </TabContainer>
                    <HiddenContainer
                      isActive={hiddenVisibleArray.includes(sideTab.label)}
                    >
                      {sideTab.subTabs?.map((subTab) => (
                        <TabContainer
                          to={getUrl(subTab, teamName, serviceId)}
                          key={`sub-${subTab}`}
                          type={"sub" as any}
                          isActive={activeSideTab === subTab}
                        >
                          {subTab}
                        </TabContainer>
                      ))}
                    </HiddenContainer>
                  </Fragment>
                ))}
              </HiddenContainer>
            </Fragment>
          ))}
        </SidebarTabContainer>
        <SideTabProfileContainer
          onClick={() => setProfileVisible(!profileVisible)}
        >
          <SideTabProfileWWrapper>
            <ProfileImage>
              <FontAwesomeIcon icon={["fas", "user"]} />
            </ProfileImage>
            <ProfileLabel>{user}</ProfileLabel>
          </SideTabProfileWWrapper>
          <FontAwesomeIcon
            icon={profileVisible ? ["far", "angle-down"] : ["far", "angle-up"]}
          />
        </SideTabProfileContainer>
        <HiddenContainer isActive={profileVisible}>
          {PROFILE_OPTIONS.map((option) => (
            <SidebarProfileTab
              onClick={() => teamName && option.onClick(teamName)}
              key={`profile-${option.label}`}
            >
              {option.icon} {option.label}
            </SidebarProfileTab>
          ))}
        </HiddenContainer>
      </HiddenSideBar>
      <IconWrapper>
        <FontAwesomeIcon
          icon={["fas", "bars"]}
          size="lg"
          onClick={() => setVisible(true)}
        />
      </IconWrapper>
      <FleetLogoContainer to={getUrl("Logo", teamName, serviceId)}>
        <FleetLogo src={FleetLogoSrc} />
        FLEET
      </FleetLogoContainer>
      <ButtonGroup>
        <ButtonNav
          isActive={activeState ? activeState === "services" : undefined}
          to={getUrl("Services", teamName, serviceId)}
        >
          SERVICES
        </ButtonNav>
        {isAdmin && (
          <ButtonNav
            to={getUrl("Admin", teamName, serviceId)}
            isActive={activeState ? activeState === "admin" : undefined}
          >
            ADMIN
          </ButtonNav>
        )}
        <ProfileDropdownWrapper>
          <Dropdown options={PROFILE_OPTIONS}>
            <ProfileImage>
              <FontAwesomeIcon icon={["fas", "user"]} />
            </ProfileImage>
            <ProfileLabel>{user}</ProfileLabel>
            <FontAwesomeIcon icon={["fas", "caret-down"]} />
          </Dropdown>
        </ProfileDropdownWrapper>
      </ButtonGroup>
      <IconWrapper>
        <FontAwesomeIcon icon={["fas", "cog"]} size="lg" />
      </IconWrapper>
    </NavBarContainer>
  );
};
