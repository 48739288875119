import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import theme from "../../theme/theme";
import { getAxiosInstance, getApiConfig } from "../../utils/api/api";
import { formatNumber } from "../../utils/formatNumber/formatNumber";
import { updateAnalyticUrl, defaultUrlParams } from "../../utils/url/url";
import { useQueryParams } from "../../utils/queryParams/useQueryParams";
import {
  AnalyticsTable,
  DonutChart,
  BarChart,
  WorldMapChart,
  Container,
  AnalyticsDatePicker,
} from "../../components";
import {
  ContentCard,
  ContentGridContainer,
  SectionCard,
  Title,
  CardTitle,
  TableWrapper,
  SubContentWrapper,
  ContentHeaderContainer,
  ContentGridCard,
  BarChartWrapper,
  BarChartTitle,
  UniqueViewerWrapper,
  UniqueViewerLabel,
  UniqueViewerNumber,
} from "./Analytics.style";

interface AnalyticsProps {}

type viewerRangeDataType = {
  date?: string;
  endTime?: string;
  startTime?: string;
  totalViewers: number;
};

type demographicsDataType = {
  totalViewersByCountry: { label: string; value: number }[];
  totalViewersByDevice: { label: string; value: number }[];
  totalViewersByOs: { label: string; value: number }[];
};

export const Analytics = (props: AnalyticsProps) => {
  const [demographicsData, setDemoGraphicData] =
    useState<demographicsDataType>();
  const [viewerRangeData, setViewerRangeData] =
    useState<viewerRangeDataType[]>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const query = useQueryParams();

  const selectedDate = query.get("selectedDate")
    ? moment(query.get("selectedDate")).toDate()
    : moment(new Date()).subtract(1, "d").toDate();
  const mode = query.get("mode") ? query.get("mode") : "date";
  const minCalendar = moment(new Date()).subtract(21, "d").toDate();
  const { teamName, serviceId } = useParams<defaultUrlParams>();
  const backendAPI = getAxiosInstance("default");

  const getViewerInsightData = async () => {
    setLoading(true);
    const apiConfig = getApiConfig();
    const selectedDateFormat = moment(selectedDate).format("YYYY-MM-DD");
    const durationFormat = `startTime=${moment(
      query.get("startTime")
    ).toISOString()}&endTime=${moment(query.get("endTime")).toISOString()}`;
    const modeViewer =
      mode === "date"
        ? "daily-viewer"
        : mode === "week"
        ? "weekly-viewer"
        : mode === "month"
        ? "monthly-viewer"
        : "time-range-viewer";
    const dataResponse = await Promise.all([
      backendAPI
        .get(
          `services/${serviceId}/${modeViewer}s${
            mode === "duration"
              ? `?${durationFormat}`
              : `/${selectedDateFormat}`
          }`,
          apiConfig
        )
        .catch((err) =>
          console.log("Error in getting viewer rage data: ", err)
        ),
      backendAPI
        .get(
          `/services/${serviceId}/${modeViewer}-demographics${
            mode === "duration"
              ? `?${durationFormat}`
              : `/${selectedDateFormat}`
          }`,
          apiConfig
        )
        .catch((err) =>
          console.log("Error in getting demographic data: ", err)
        ),
    ]);

    if (dataResponse) {
      const viewerRangeResponse = dataResponse[0];
      const demographicsResponse = dataResponse[1];
      if (viewerRangeResponse) {
        const { data } = viewerRangeResponse.data;
        setViewerRangeData(data);
      }
      if (demographicsResponse) {
        setDemoGraphicData(demographicsResponse.data);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getViewerInsightData();
  }, [history.location]);

  return (
    <Container activeTab="Analytics" activeSideTab="Viewer Insight">
      <ContentCard>
        <ContentHeaderContainer>
          <Title>Viewer Insight</Title>
          <AnalyticsDatePicker
            mode={mode as any}
            selectedDate={
              mode === "duration"
                ? moment(query.get("startTime")).toDate()
                : selectedDate
            }
            endTime={moment(query.get("endTime")).toDate()}
            onModeChange={(mode) => {
              if (mode === "duration") {
                const initStartTime = moment(new Date())
                  .subtract(1, "d")
                  .toDate();
                const initEndTime = moment(initStartTime).add(30, "m").toDate();
                updateAnalyticUrl(teamName, serviceId, "duration", {
                  startTime: initStartTime,
                  endTime: initEndTime,
                });
              } else {
                const initSelectedDate = moment(new Date())
                  .subtract(1, "d")
                  .toDate();
                updateAnalyticUrl(teamName, serviceId, mode, {
                  selectDate: initSelectedDate,
                });
              }
            }}
            onDateChange={(newSelectedDate, newSelectedEndDate) => {
              if (mode === "duration") {
                updateAnalyticUrl(teamName, serviceId, "duration", {
                  startTime: newSelectedDate,
                  endTime: newSelectedEndDate,
                });
              } else {
                updateAnalyticUrl(teamName, serviceId, mode as string, {
                  selectDate: newSelectedDate,
                });
              }
            }}
          />
        </ContentHeaderContainer>
        <SectionCard>
          <ContentGridCard>
            <BarChartWrapper>
              <BarChartTitle>
                Unique Viewers on Last 7{" "}
                {mode === "date" || mode === "duration"
                  ? "Days"
                  : mode === "week"
                  ? "Weeks"
                  : "Months"}
              </BarChartTitle>
              <BarChart
                label="Unique Viewers"
                categories={
                  !loading && viewerRangeData
                    ? viewerRangeData.map((viewerData) => {
                        if (mode === "date")
                          return moment(viewerData.date).format("DD MMM YYYY");
                        else if (mode === "week")
                          return `${moment(viewerData.date).format(
                            "DD MMM YYYY"
                          )} - ${moment(viewerData.date)
                            .add(6, "d")
                            .format("DD MMM YYYY")}`;
                        else if (mode === "month")
                          return moment(viewerData.date).format("MMM YYYY");
                        else
                          return `${moment(viewerData.startTime).format(
                            "DD MMM YYYY [HH:mm -"
                          )} ${moment(viewerData.endTime).format(" HH:mm]")}`;
                      })
                    : []
                }
                loading={loading}
                data={
                  !loading && viewerRangeData
                    ? viewerRangeData.map(
                        (viewerData) => viewerData.totalViewers
                      )
                    : []
                }
                onBarClick={(category, value) => {
                  if (mode === "date" || mode === "month") {
                    updateAnalyticUrl(teamName, serviceId, mode, {
                      selectDate: new Date(category),
                    });
                  } else if (mode === "week") {
                    const firstDate = category.split("-")[0];
                    updateAnalyticUrl(teamName, serviceId, mode, {
                      selectDate: new Date(firstDate),
                    });
                  } else if (mode === "duration") {
                    if (selectedDate.getDate() === minCalendar.getDate()) {
                      return;
                    }
                    const newDate = category.split("[")[0];
                    const newTime = new Date(newDate);
                    const newEndTime = new Date(newDate);
                    const endTime = moment(query.get("endTime")).toDate();
                    newTime.setHours(selectedDate.getHours());
                    newTime.setMinutes(selectedDate.getMinutes());
                    newEndTime.setHours(endTime.getHours());
                    newEndTime.setMinutes(endTime.getMinutes());
                    updateAnalyticUrl(teamName, serviceId, mode, {
                      startTime: newTime,
                      endTime: newEndTime,
                    });
                  }
                }}
              />
            </BarChartWrapper>
            <UniqueViewerWrapper>
              <UniqueViewerLabel>
                <FontAwesomeIcon icon={["fas", "user"]} />
                Unique Viewers
              </UniqueViewerLabel>
              <UniqueViewerNumber>
                {loading ? (
                  <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
                ) : viewerRangeData ? (
                  formatNumber(
                    viewerRangeData[viewerRangeData.length - 1].totalViewers
                  )
                ) : (
                  "No Viewer"
                )}
              </UniqueViewerNumber>
            </UniqueViewerWrapper>
          </ContentGridCard>
        </SectionCard>
        <SectionCard>
          <CardTitle>Countries</CardTitle>
          <ContentGridContainer>
            <SubContentWrapper>
              <WorldMapChart
                data={
                  !loading && !!demographicsData?.totalViewersByCountry
                    ? demographicsData?.totalViewersByCountry.map((country) => {
                        return { country: country.label, value: country.value };
                      })
                    : []
                }
              />
            </SubContentWrapper>
            <SubContentWrapper>
              <AnalyticsTable
                loading={loading}
                data={
                  !loading && demographicsData?.totalViewersByCountry
                    ? demographicsData?.totalViewersByCountry
                    : []
                }
                category="Country"
                ratio={62.5}
              />
            </SubContentWrapper>
          </ContentGridContainer>
        </SectionCard>
        <ContentGridContainer>
          <SectionCard>
            <CardTitle>Devices</CardTitle>
            <SubContentWrapper>
              <DonutChart
                loading={loading}
                categories={
                  !loading && demographicsData?.totalViewersByDevice
                    ? demographicsData.totalViewersByDevice.map(
                        (device) => device.label
                      )
                    : []
                }
                data={
                  !loading && demographicsData?.totalViewersByDevice
                    ? demographicsData.totalViewersByDevice.map(
                        (device) => device.value
                      )
                    : []
                }
              />
              <TableWrapper>
                <AnalyticsTable
                  totalValue={
                    viewerRangeData &&
                    viewerRangeData[viewerRangeData.length - 1].totalViewers
                  }
                  colors={Object.values(theme.colors.graph)}
                  loading={loading}
                  data={
                    !loading && demographicsData?.totalViewersByDevice
                      ? demographicsData?.totalViewersByDevice
                      : []
                  }
                  category="Device"
                />
              </TableWrapper>
            </SubContentWrapper>
          </SectionCard>
          <SectionCard>
            <CardTitle>Operation Systems</CardTitle>
            <SubContentWrapper>
              <DonutChart
                loading={loading}
                categories={
                  !loading && demographicsData?.totalViewersByOs
                    ? demographicsData.totalViewersByOs.map((os) => os.label)
                    : []
                }
                data={
                  !loading && demographicsData?.totalViewersByOs
                    ? demographicsData.totalViewersByOs.map((os) => os.value)
                    : []
                }
              />
              <TableWrapper>
                <AnalyticsTable
                  totalValue={
                    viewerRangeData &&
                    viewerRangeData[viewerRangeData.length - 1].totalViewers
                  }
                  loading={loading}
                  colors={Object.values(theme.colors.graph)}
                  data={
                    !loading && demographicsData?.totalViewersByOs
                      ? demographicsData?.totalViewersByOs
                      : []
                  }
                  category="OS"
                />
              </TableWrapper>
            </SubContentWrapper>
          </SectionCard>
        </ContentGridContainer>
      </ContentCard>
    </Container>
  );
};
