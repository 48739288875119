import { ReactElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { getAxiosInstance, getApiConfig } from "../../utils/api/api";
import { defaultUrlParams } from "../../utils/url/url";
import { MainTabs } from "../mainTabs/MainTabs";
import { SideTabs } from "../sideTabs/SideTabs";
import { NavBar } from "../navBar/NavBar";
import { TOPTABS, MainTabType, SideTabType } from "./TabData";
import {
  PageContainer,
  HeaderContainer,
  HeaderWrapper,
  ContentContainer,
  Content,
  SideTabWrapper,
  ContentWrapper,
  ContentCardWrapper,
  ServiceLabelContainer,
  ServiceLabel,
  ServiceUrl,
} from "./Container.style";

type serviceInfoDataType = {
  id: number;
  groupId: number;
  hostname: string;
  alias: string;
  type: string;
};

interface ContainerProps {
  /**
   * Set active tab
   */
  activeTab: MainTabType;
  /**
   * Set active state in nav bar
   */
  activeNavBar?: string;
  /**
   * Set active side tab
   */
  activeSideTab: SideTabType;
  /**
   * Children component
   */
  children: ReactElement;
}

export const Container = (props: ContainerProps) => {
  const { activeTab, activeSideTab, activeNavBar, children } = props;
  const [serviceInfo, setServiceInfo] = useState<serviceInfoDataType>();
  const [user, setUser] = useState<any>();
  const history = useHistory();
  const { teamName, serviceId } = useParams<defaultUrlParams>();
  const backendAPI = getAxiosInstance("default");

  const getServiceInfoData = async () => {
    const apiConfig = getApiConfig();
    const serviceInfoResponse = await backendAPI
      .get(`services/${serviceId}`, apiConfig)
      .catch((err) => console.log("Error in getting service info data: ", err));

    if (serviceInfoResponse) {
      setServiceInfo(serviceInfoResponse.data);
    }
  };

  const getUserData = async () => {
    const apiConfig = getApiConfig(teamName);

    const userResponse = await backendAPI
      .get("me", apiConfig)
      .catch((err) => console.log("Error in getting user data: ", err));

    if (userResponse) {
      setUser(userResponse.data);
    }
  };

  useEffect(() => {
    getUserData();
    getServiceInfoData();
  }, [history.location]);

  return (
    <PageContainer>
      <HeaderContainer>
        <NavBar
          user={user ? user.email : "user@inox.co.th"}
          groupId={serviceInfo?.groupId}
          teamName={teamName}
          serviceId={serviceId}
          activeState={activeNavBar}
          activeSideTab={activeSideTab}
          activeTab={activeTab}
          tabs={TOPTABS}
          isAdmin={user?.role === "admin"}
          serviceLabel={serviceInfo?.alias}
          serviceUrl={serviceInfo?.hostname}
        />
        <HeaderWrapper>
          <ServiceLabelContainer>
            <ServiceLabel>{serviceInfo?.alias}</ServiceLabel>
            <ServiceUrl>{serviceInfo?.hostname}</ServiceUrl>
          </ServiceLabelContainer>
          <MainTabs
            activeTab={activeTab}
            teamName={teamName}
            serviceId={serviceId}
            tabs={TOPTABS}
          />
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <ContentWrapper>
          <Content>
            {!!TOPTABS.find((tab) => tab.label === activeTab)?.sideTabs ? (
              <SideTabWrapper>
                <SideTabs
                  teamName={teamName}
                  serviceId={serviceId}
                  activeTab={activeSideTab}
                  tabs={
                    TOPTABS.find((tab) => tab.label === activeTab)?.sideTabs
                  }
                />
              </SideTabWrapper>
            ) : undefined}
            <ContentCardWrapper>{children}</ContentCardWrapper>
          </Content>
        </ContentWrapper>
      </ContentContainer>
    </PageContainer>
  );
};
