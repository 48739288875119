import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

interface LinkProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
  className?: any;
}

export const Link = (props: LinkProps) => {
  const { to, children, onClick, className, ...rest } = props;

  const parseTo = (to: string) => {
    let parser = document.createElement("a");
    parser.href = to;
    return parser;
  };
  const isInternal = (toLocation: HTMLAnchorElement) => {
    return window.location.host === toLocation.host;
  };
  const toLocation = parseTo(to);
  const isInternalUrl = isInternal(toLocation);
  if (onClick) {
    return (
      <div onClick={onClick} className={className}>
        {children}
      </div>
    );
  } else {
    if (isInternalUrl) {
      return (
        <RouterLink to={toLocation.pathname} className={className}>
          {children}
        </RouterLink>
      );
    } else {
      return (
        <a href={to} className={className}>
          {children}
        </a>
      );
    }
  }
};
