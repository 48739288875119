import { TabContainer, Tab } from "./MainTabs.style";

import { MainTabType, MainTab } from "../container/TabData";
import getUrl from "../../utils/url/url";

interface MainTabsProps {
  /**
   * team's name
   */
  teamName: string;
  /**
   * service's id
   */
  serviceId: string;
  /**
   * Array of tab
   */
  tabs: MainTab[];
  /**
   * Set active tab
   * @default tabs[0]
   */
  activeTab: MainTabType;
}

export const MainTabs = (props: MainTabsProps) => {
  const { teamName, serviceId, tabs, activeTab = tabs[0] } = props;

  return (
    <TabContainer>
      {tabs.map((tab) => (
        <Tab
          to={getUrl(tab.label, teamName, serviceId)}
          isActive={activeTab === tab.label}
          key={`key-${tab.label}`}
        >
          {tab.label}
        </Tab>
      ))}
    </TabContainer>
  );
};
