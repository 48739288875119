export enum size {
  xxs = 375,
  xs = 480,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
}

// Show when width > selected size
export const maxLayout = {
  xxs: `(max-width: ${size.xxs}px)`,
  xs: `(max-width: ${size.xs}px)`,
  sm: `(max-width: ${size.sm}px)`,
  md: `(max-width: ${size.md}px)`,
  lg: `(max-width: ${size.lg}px)`,
  xl: `(max-width: ${size.xl}px)`,
  xxl: `(max-width: ${size.xxl}px)`,
};

// Show when width < selected size
export const minLayout = {
  xxs: `(min-width: ${size.xxs + 1}px)`,
  xs: `(min-width: ${size.xs + 1}px)`,
  sm: `(min-width: ${size.sm + 1}px)`,
  md: `(min-width: ${size.md + 1}px)`,
  lg: `(min-width: ${size.lg + 1}px)`,
  xl: `(min-width: ${size.xl + 1}px)`,
  xxl: `(min-width: ${size.xxl + 1}px)`,
};
