export type MainTabType =
  | "Overview"
  | "Analytics"
  | "Schedules"
  | "Geoblock"
  | "Security"
  | "Settings"
  | "SSL/TLS";
type AnalyticsSideTabType =
  | "Monitor"
  | "Subscribers"
  | "Report"
  | "Traffic"
  | "Access"
  | "Streaming"
  | "Content Info"
  | "Monthly"
  | "Weekly"
  | "Ranking"
  | "Viewer Insight"
  | "Monthly Summary";
type SchedulesSideTabType = "Program" | "Report" | "Channel";
export type SideTabType = AnalyticsSideTabType | SchedulesSideTabType;

export interface MainTab {
  label: MainTabType;
  sideTabs?: SideTab[];
}

export interface SideTab {
  label: SideTabType;
  subTabs?: SideTabType[];
}

const SCHEDULESSIDETAB: SideTab[] = [
  {
    label: "Program",
  },
  {
    label: "Report",
  },
  {
    label: "Channel",
  },
];

const ANALYTICSSIDETABS: SideTab[] = [
  {
    label: "Monitor",
    subTabs: ["Traffic", "Access", "Streaming", "Content Info"],
  },
  {
    label: "Subscribers",
    subTabs: ["Monthly", "Weekly", "Ranking", "Viewer Insight"],
  },
  {
    label: "Report",
    subTabs: ["Monthly Summary"],
  },
];

export const TOPTABS: MainTab[] = [
  { label: "Overview" },
  { label: "Analytics", sideTabs: ANALYTICSSIDETABS },
  { label: "Schedules", sideTabs: SCHEDULESSIDETAB },
  { label: "Geoblock" },
  { label: "Security" },
  { label: "Settings" },
  { label: "SSL/TLS" },
];
