import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  SelectButton,
  SelectOverlay,
  OptionsContainer,
  Option,
  SelectedValueLabel,
} from "./Select.style";

export interface OptionProps {
  /**
   * Option's label
   */
  label: string;
  /**
   * Option's value
   */
  value: string | number;
}

interface SelectProps {
  /**
   * Array of option
   */
  options: OptionProps[];
  /**
   * Default selected value
   * @default first option's value
   */
  defaultValue?: any;
  /**
   * Call when selected value changed
   */
  onSelect?: (selectedValue: any) => void;
  /**
   * Custom border radius
   * @default 3px
   */
  borderRadius?: string;
}

export const Select = (props: SelectProps) => {
  const {
    options,
    defaultValue = options[0].value,
    onSelect,
    borderRadius,
  } = props;
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const displaySelectedOption = () => {
    let inputLabel = "";

    options.forEach((option) => {
      if (option.value === value) inputLabel = option.label;
    });

    return inputLabel;
  };

  return (
    <>
      <SelectButton
        onClick={() => setVisible(true)}
        borderRadius={borderRadius}
      >
        <SelectedValueLabel>{displaySelectedOption()}</SelectedValueLabel>
        <FontAwesomeIcon icon={["fas", "angle-down"]} />
      </SelectButton>
      <SelectOverlay isActive={visible} onClick={() => setVisible(false)} />
      <OptionsContainer isActive={visible}>
        {options.map((option) => (
          <Option
            key={option.value}
            onClick={() => {
              onSelect && onSelect(option.value);
              setValue(option.value);
              setVisible(false);
            }}
            isSelected={option.value === value}
          >
            {option.label}
          </Option>
        ))}
      </OptionsContainer>
    </>
  );
};
