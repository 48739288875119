import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.stream.navy};
  opacity: 0.7;
  z-index: 1;
`;

export const ModalCard = styled.div`
  position: absolute;
  margin: 16px 0;
  z-index: 5;
  max-width: 932px;
  width: 90%;
  transform: translate(-50%, 0);
  top: 0;
  left: 50%;
  overflow-y: auto;
  background: ${(props) => props.theme.colors.stream.white};
  padding: 24px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  @media ${maxLayout.sm} {
    margin: 24px 0;
    padding: 16px;
  }
`;

export const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.stream.grey1};
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  :hover {
    cursor: pointer;
  }
`;

export const ModalTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
  overflow: hidden;
  overflow-wrap: break-word;

  @media ${maxLayout.sm} {
    font-size: 18px;
    line-height: 30px;
  }
`;

export const ModalContentTitle = styled.div<{line?: boolean}>`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey3};

${props => {
  if(props.line){
    return css`
    padding-bottom: 8px;
    border-bottom: 1px solid ${props.theme.colors.stream.grey6};`
  }
}}

  @media ${maxLayout.sm} {
    font-size: 14px;
  }
`;

export const ModalContentText = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey1};
  overflow: hidden;
  overflow-wrap: break-word;
  padding-top: 5px;

  @media ${maxLayout.sm} {
    font-size: 14px;
  }
`;

export const ModalContentValue = styled(ModalContentText)`
  font-size: 24px;

  @media ${maxLayout.sm} {
    font-size: 18px;
  }
`;

export const GridTwoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  margin-top: 16px;
  @media ${maxLayout.sm} {
    grid-template-columns: auto;
    width: 100%;
  }
`;

export const GridThreeLabelContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 16px;
`;

export const GridExtraContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 10px;
  margin-top: 16px;
`;

export const GridThreeValueContainer = styled(GridThreeLabelContainer)`
  margin-bottom: 16px;
  margin-top: 0;
`;

export const ModalContentSection = styled.div`
  display: flex;
  flex-direction: column;
`;
