import styled from "styled-components";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const ContentCard = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
`;

export const SectionCard = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 8px;
  color: ${(props) => props.theme.colors.stream.grey1};
  border-bottom: 1px solid ${(props) => props.theme.colors.stream.grey6};
`;

export const ContentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  @media ${maxLayout.sm} {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 16px;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.stream.grey1};

  @media ${maxLayout.sm} {
    margin-bottom: 16px;
  }
`;

export const ContentGridContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 16px;
  width: calc(100% - 16px);

  @media ${maxLayout.sm} {
    grid-template-columns: auto;
    width: 100%;
  }
`;

export const SubContentWrapper = styled.div`
  padding-top: 16px;
`;

export const ContentGridCard = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  border: 1px solid ${(props) => props.theme.colors.stream.grey7};
  border-radius: 5px;

  @media ${maxLayout.sm} {
    grid-template-columns: auto;
  }
`;

export const BarChartTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey1};

  @media ${maxLayout.sm} {
    font-size: 14px;
  }
`;

export const BarChartWrapper = styled.div`
  padding: 24px;
  border-right: 1px solid ${(props) => props.theme.colors.stream.grey7};

  @media ${maxLayout.sm} {
    padding: 16px 16px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.stream.grey7};
    border-right: transparent;
  }
`;

export const UniqueViewerWrapper = styled.div`
  align-self: center;
  text-align: center;

  @media ${maxLayout.sm} {
    padding: 16px 0;
  }
`;

export const UniqueViewerLabel = styled.div`
  font-size: 18px;
  color: ${(props) => props.theme.colors.stream.grey2};
  display: flex;
  align-items: baseline;
  justify-content: center;
  > svg {
    margin-right: 8px;
  }

  @media ${maxLayout.sm} {
    font-size: 14px;
  }
`;

export const UniqueViewerNumber = styled.div`
  font-size: 64px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey1};
  padding-top: 22px;

  @media ${maxLayout.sm} {
    font-size: 48px;
    padding-top: 12px;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 16px;
`;
