import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";

import {
  TimepickerWrapper,
  ClearIconWrapper,
  InputIconWrapper,
} from "./TimePicker.style";

interface TimePickerProps {
  /**
   * Default time
   */
  defaultTime?: Date;
  /**
   * Call when selected time changed
   */
  onChange?: (time: Moment) => void;
}

export const StyledTimePicker = (props: TimePickerProps) => {
  const { defaultTime, onChange } = props;

  return (
    <TimepickerWrapper
      defaultValue={moment(defaultTime)}
      showSecond={false}
      placeholder={"HH:mm"}
      inputIcon={
        <InputIconWrapper>
          <FontAwesomeIcon icon={["fas", "clock"]} />
        </InputIconWrapper>
      }
      clearIcon={
        <ClearIconWrapper>
          <FontAwesomeIcon icon={["fal", "times"]} />
        </ClearIconWrapper>
      }
      onChange={(time: Moment) => {
        onChange && onChange(time);
      }}
    />
  );
};
