import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getIconFlag = (contryCode: string) => {
  if (contryCode.toLowerCase() === "unknown") {
    return <FontAwesomeIcon icon={["fas", "question-circle"]} />;
  } else if (contryCode.toLowerCase() === "other") {
    return <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />;
  } else {
    return (
      <span
        className={`flag-icon flag-icon-${contryCode.toLowerCase()}`}
      ></span>
    );
  }
};
