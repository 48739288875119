import { useState } from "react";
import { getName } from "country-list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Icon } from "../icon/Icon";
import { getIconDevice, getIconFlag, getIconOS } from "../../utils/icon";
import { useWindowDimensions } from "../../utils/windowDimensions/useWindowDimensions";
import { formatNumber } from "../../utils/formatNumber/formatNumber";
import { size } from "../../utils/breakpoint/breakpoint";

import {
  Table,
  KeyHeader,
  ViewHeader,
  TableContent,
  TableRow,
  SeeMoreButton,
  NoDataCard,
  NoDataLabel,
  TableWrapper,
  RatioWrapper,
  StyledTableHead,
} from "./AnalyticsTable.style";

type iconCategory = "Device" | "Country" | "OS";

interface AnalyticsTableProps {
  /**
   * Analytics table's data
   */
  data: { label: string; value: number }[];
  /**
   * total value (show each value in %)
   */
  totalValue?: number;
  /**
   * Analytics table data's category
   */
  category: iconCategory;
  /**
   * Set of color
   */
  colors?: string[];
  /**
   * Limit of row that will show while see more button is shown
   * @default 5
   */
  rowLimit?: number;
  /**
   * Set height limit of table
   */
  height?: number;
  /**
   * Enable ratio mode (height should be undefined)
   * 8 : 5 = (5/8)*100 = 62.5
   * ratio = 62.5
   */
  ratio?: number;
  /**
   * Set loading state
   */
  loading?: boolean;
}

const getIconFromCategory = (label: string, category: iconCategory) => {
  switch (category) {
    case "Device":
      return getIconDevice(label);
    case "Country":
      return getIconFlag(label);
    case "OS":
      return getIconOS(label);
    default:
      return null;
  }
};

export const AnalyticsTable = (props: AnalyticsTableProps) => {
  const {
    data,
    category,
    totalValue,
    rowLimit = 5,
    colors,
    height,
    ratio,
    loading,
  } = props;

  const [maxRow, setMaxRow] = useState(rowLimit);
  const { width } = useWindowDimensions();

  return (
    <RatioWrapper
      height={height ? `${height}px` : undefined}
      ratio={ratio ? `${ratio}%` : undefined}
    >
      <TableWrapper
        height={height ? `${height}px` : undefined}
        ratio={ratio ? `${ratio}%` : undefined}
      >
        <Table>
          <StyledTableHead>
            <tr>
              <KeyHeader>{category}</KeyHeader>
              <ViewHeader>Views</ViewHeader>
              {totalValue && <ViewHeader>% Views</ViewHeader>}
            </tr>
          </StyledTableHead>
          <tbody>
            {data.map((rowData, index) => {
              if (width >= size.sm ? true : index < maxRow) {
                const viewlabel = rowData.label;
                const viewValue = rowData.value;
                return (
                  <TableRow
                    key={`analytic-row-${index}`}
                    color={colors ? colors[index % colors.length] : "none"}
                  >
                    <TableContent color="none">
                      <Icon
                        icon={getIconFromCategory(viewlabel, category)}
                        label={
                          viewlabel.toLowerCase() === "unknown"
                            ? "Unknown"
                            : category === "Country" &&
                              viewlabel.toLowerCase() !== "other"
                            ? getName(viewlabel)
                            : viewlabel
                        }
                      />
                    </TableContent>
                    <TableContent isAlignRight>
                      {formatNumber(viewValue)}
                    </TableContent>
                    {totalValue && (
                      <TableContent isAlignRight>
                        {((viewValue / totalValue) * 100).toFixed(1)}%
                      </TableContent>
                    )}
                  </TableRow>
                );
              } else {
                return undefined;
              }
            })}
          </tbody>
        </Table>
      </TableWrapper>
      {Object.entries(data).length !== 0 &&
        maxRow !== Object.entries(data).length + 1 && (
          <SeeMoreButton
            onClick={() => setMaxRow(Object.entries(data).length + 1)}
          >
            See more
          </SeeMoreButton>
        )}
      {Object.entries(data).length === 0 && (
        <NoDataCard
          height={height ? `${height}px` : undefined}
          ratio={ratio ? `${ratio}%` : undefined}
        >
          {loading ? (
            <FontAwesomeIcon icon={["fas", "spinner"]} size="4x" pulse />
          ) : (
            <>
              <FontAwesomeIcon icon={["fas", "exclamation"]} size="4x" />
              <NoDataLabel>No Data</NoDataLabel>
            </>
          )}
        </NoDataCard>
      )}
    </RatioWrapper>
  );
};
