import { useParams } from "react-router-dom";
import { defaultUrlParams } from "../../utils/url/url";

export const Dashboard = () => {
  const { teamName, serviceId } = useParams<defaultUrlParams>();

  return (
    <div>
      <a href={`/${teamName}/services/627/analytics/analytic`}>
        analytics public
      </a>
      <br />
      <a href={`/${teamName}/services/627/schedule/management`}>
        schedules protected
      </a>
    </div>
  );
};
