import { Fragment } from "react";

import { SideTab, SideTabType } from "../container/TabData";
import getUrl from "../../utils/url/url";
import {
  SideTabContainer,
  SideTabWithStyled,
  LabelWrapper,
} from "./SideTabs.style";

interface SideTabContainerProps {
  /**
   * team's name
   */
  teamName: string;
  /**
   * service's id
   */
  serviceId: string;
  /**
   * Array of tab's title
   */
  tabs?: SideTab[];
  /**
   * Set active side tab
   */
  activeTab?: SideTabType;
}

export const SideTabs = (props: SideTabContainerProps) => {
  const { teamName, serviceId, tabs, activeTab } = props;

  return (
    <SideTabContainer>
      {!!tabs &&
        tabs.map((tab) => (
          <Fragment key={`key-side-${tab.label}`}>
            <SideTabWithStyled
              isMainTab
              isActive={!tab.subTabs && activeTab === tab.label}
              canClick={!tab.subTabs}
              to={getUrl(tab.label, teamName, serviceId)}
              onClick={tab.subTabs ? () => {} : undefined}
            >
              <LabelWrapper>{tab.label}</LabelWrapper>
            </SideTabWithStyled>
            {tab.subTabs &&
              tab.subTabs.map((subTab) => (
                <SideTabWithStyled
                  canClick={true}
                  isActive={activeTab === subTab}
                  to={getUrl(subTab, teamName, serviceId)}
                  key={`key-${subTab}`}
                >
                  <LabelWrapper>{subTab}</LabelWrapper>
                </SideTabWithStyled>
              ))}
          </Fragment>
        ))}
    </SideTabContainer>
  );
};
