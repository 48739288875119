import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Calendar } from "react-modern-calendar-datepicker";
import moment from "moment";

import theme from "../../theme/theme";
import { size } from "../../utils/breakpoint/breakpoint";
import { useWindowDimensions } from "../../utils/windowDimensions/useWindowDimensions";
import { Button } from "../button/Button";
import { OptionProps, Select } from "../select/Select";
import { StyledTimePicker } from "../timePicker/TimePicker";

import {
  ButtonGroup,
  DateButton,
  DateButtonWrapper,
  HiddenContent,
  Hint,
  SelectedContainer,
  Label,
  SelectWrapper,
  MonthPickerContainer,
  DurationPickerContainer,
  TimePickerContainer,
  DurationLabel,
  TimeLabel,
  CalendarExtraContent,
  DatePickerOverlay,
  ButtonContainer,
  IconWrapper,
  IconButtonContainer,
  SelectButtonWrapper,
} from "./AnalyticsDatePicker.style";

type dateMode = "date" | "week" | "month" | "duration";

interface AnalyticsDatePickerProps {
  /**
   * Set default date
   */
  selectedDate: Date;
  /**
   * Set default mode
   */
  mode: dateMode;
  /**
   * Set end time
   */
  endTime?: Date;
  /**
   * Called when selected date is changed
   */
  onDateChange?: (selectedDate: Date, selectedEndDate?: Date) => void;
  /**
   * Call when slected mode is changed
   */
  onModeChange?: (selectedMode: dateMode) => void;
}

const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MODE_OPTIONS = [
  { label: "Date", value: "date" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Duration", value: "duration" },
];

const HALF_HOUR = 30 * 60000;

export const AnalyticsDatePicker = (props: AnalyticsDatePickerProps) => {
  const {
    mode,
    selectedDate,
    endTime = new Date(),
    onDateChange,
    onModeChange,
  } = props;
  const [yearBackup, setYearBackup] = useState(new Date());
  const [monthBackup, setMonthBackup] = useState(new Date());
  const [selectedDateBackup, setSelectedDateBackup] = useState(
    new Date(selectedDate.getTime())
  );
  const [selectedTimeBackup, setSelectedTimeBackup] = useState(
    new Date(selectedDate.getTime())
  );
  const [selectedEndTimeBackup, setSelectedEndTimeBackup] = useState(
    new Date(endTime.getTime() + HALF_HOUR)
  );
  const [visible, setVisible] = useState(false);
  const { width } = useWindowDimensions();
  const minCalendar = moment(new Date()).subtract(21, "d").toDate();
  const maxCalendar = moment(new Date()).subtract(1, "d").toDate();

  const displaySelectedDate = () => {
    if (mode === "date") {
      return moment(selectedDate).format("DD MMM YYYY");
    }
    if (mode === "week") {
      const firstDayOfWeek = new Date();
      const lastDayOfWeek = new Date();
      let dayOfWeek = selectedDate.getDay();
      firstDayOfWeek.setDate(selectedDate.getDate() - dayOfWeek);
      lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
      return `${moment(firstDayOfWeek).format("DD MMM YYYY")} - ${moment(
        lastDayOfWeek
      ).format("DD MMM YYYY")}`;
    }
    if (mode === "month") {
      return moment(selectedDate).format("MMM YYYY");
    } else {
      return `${moment(selectedDate).format("DD MMM YYYY [HH:mm -")} ${moment(
        endTime
      ).format(" HH:mm]")}`;
    }
  };

  const monthOptions = () => {
    let options: OptionProps[] = [];

    MONTHS.map((month, index) => options.push({ label: month, value: index }));

    return options;
  };

  const yearOptions = () => {
    let options: OptionProps[] = [];

    MONTHS.map((month, index) => {
      const date = new Date();

      date.setFullYear(date.getFullYear() - index);

      return options.push({
        label: date.getFullYear().toString(),
        value: date.getFullYear(),
      });
    });

    return options;
  };

  const checkDisabledState = () => {
    if (mode === "date" || mode === "duration") {
      return (
        moment(selectedDate).format("DD MMM YYYY") ===
        moment(maxCalendar).format("DD MMM YYYY")
      );
    } else if (mode === "month") {
      return selectedDate.getMonth() === maxCalendar.getMonth();
    } else if (mode === "week") {
      selectedDate.getDay();
      const firstDayOfSelectedWeek = new Date(selectedDate);
      const firstDayOMaxCalendarWeek = new Date(maxCalendar);
      firstDayOfSelectedWeek.setDate(
        firstDayOfSelectedWeek.getDate() - firstDayOfSelectedWeek.getDay()
      );
      firstDayOMaxCalendarWeek.setDate(
        firstDayOMaxCalendarWeek.getDate() - firstDayOMaxCalendarWeek.getDay()
      );
    }
  };

  useEffect(() => {
    setSelectedDateBackup(new Date(selectedDate.getTime()));
    setSelectedTimeBackup(new Date(selectedDate.getTime()));
    setSelectedEndTimeBackup(new Date(selectedDate.getTime() + HALF_HOUR));
  }, [selectedDate]);

  return (
    <ButtonContainer>
      <ButtonGroup>
        <SelectButtonWrapper>
          <Select
            options={MODE_OPTIONS}
            defaultValue={mode}
            borderRadius={"3px 0 0 3px"}
            onSelect={(mode) => {
              onModeChange && onModeChange(mode);
              setVisible(true);
            }}
          />
        </SelectButtonWrapper>
        <DateButtonWrapper>
          <DateButton onClick={() => setVisible(true)}>
            {displaySelectedDate()}
            <FontAwesomeIcon
              icon={["fas", "calendar-alt"]}
              color={theme.colors.stream.grey2}
            />
          </DateButton>
          <DatePickerOverlay
            isActive={visible}
            onClick={() => {
              setYearBackup(selectedDate);
              setMonthBackup(selectedDate);
              setSelectedDateBackup(selectedDate);
              setSelectedTimeBackup(selectedDate);
              setSelectedEndTimeBackup(
                new Date(selectedDate.getTime() + HALF_HOUR)
              );
              setVisible(false);
            }}
          />
          <HiddenContent isActive={visible}>
            {mode === "date" && (
              <Calendar
                colorPrimary={theme.colors.ci.primary}
                calendarClassName="responsive-calendar"
                value={{
                  day: selectedDate.getDate(),
                  month: selectedDate.getMonth() + 1,
                  year: selectedDate.getFullYear(),
                }}
                maximumDate={{
                  year: maxCalendar.getFullYear(),
                  month: maxCalendar.getMonth() + 1,
                  day: maxCalendar.getDate(),
                }}
                onChange={(selectDate) => {
                  if (selectDate) {
                    const newDate = new Date(
                      selectDate.year,
                      selectDate.month - 1,
                      selectDate.day
                    );
                    setVisible(false);
                    onDateChange && onDateChange(newDate);
                  }
                }}
              />
            )}
            {mode === "week" && (
              <>
                <Calendar
                  colorPrimary={theme.colors.ci.primary}
                  calendarClassName="responsive-calendar"
                  value={{
                    day: selectedDate.getDate(),
                    month: selectedDate.getMonth() + 1,
                    year: selectedDate.getFullYear(),
                  }}
                  maximumDate={{
                    year: maxCalendar.getFullYear(),
                    month: maxCalendar.getMonth() + 1,
                    day: maxCalendar.getDate(),
                  }}
                  onChange={(selectDate) => {
                    if (selectDate) {
                      const newDate = new Date(
                        selectDate.year,
                        selectDate.month - 1,
                        selectDate.day
                      );
                      setVisible(false);
                      onDateChange && onDateChange(newDate);
                    }
                  }}
                />
                <CalendarExtraContent>
                  <Hint>
                    Note:
                    วันที่เลือกจะเป็นวันใดก็ได้ภายในสัปดาห์ที่ท่านต้องการดูข้อมูล
                  </Hint>
                </CalendarExtraContent>
              </>
            )}
            {mode === "month" && (
              <MonthPickerContainer>
                <SelectedContainer>
                  <Label>Month:</Label>
                  <SelectWrapper>
                    <Select
                      options={monthOptions()}
                      defaultValue={selectedDate.getMonth()}
                      onSelect={(selectedValue) => {
                        const month = new Date(selectedDate.getTime());
                        month.setMonth(parseInt(selectedValue));
                        setMonthBackup(month);
                      }}
                    />
                  </SelectWrapper>
                </SelectedContainer>
                <SelectedContainer>
                  <Label>Year:</Label>
                  <SelectWrapper>
                    <Select
                      options={yearOptions()}
                      defaultValue={selectedDate.getFullYear()}
                      onSelect={(selectedValue) => {
                        const year = new Date(selectedDate.getTime());
                        year.setFullYear(parseInt(selectedValue));
                        setYearBackup(year);
                      }}
                    />
                  </SelectWrapper>
                </SelectedContainer>
                <Button
                  onClick={() => {
                    const newSelectedDate = new Date(selectedDate.getTime());
                    newSelectedDate.setMonth(monthBackup.getMonth());
                    newSelectedDate.setFullYear(yearBackup.getFullYear());
                    setVisible(false);
                    onDateChange && onDateChange(newSelectedDate);
                  }}
                  fullWidth
                >
                  Apply
                </Button>
              </MonthPickerContainer>
            )}
            {mode === "duration" && (
              <DurationPickerContainer>
                <Calendar
                  colorPrimary={theme.colors.ci.primary}
                  calendarClassName="responsive-calendar"
                  maximumDate={{
                    year: maxCalendar.getFullYear(),
                    month: maxCalendar.getMonth() + 1,
                    day: maxCalendar.getDate(),
                  }}
                  minimumDate={{
                    year: minCalendar.getFullYear(),
                    month: minCalendar.getMonth() + 1,
                    day: minCalendar.getDate(),
                  }}
                  value={{
                    day: selectedDateBackup.getDate(),
                    month: selectedDateBackup.getMonth() + 1,
                    year: selectedDateBackup.getFullYear(),
                  }}
                  onChange={(selectDate) => {
                    if (selectDate) {
                      setSelectedDateBackup(
                        new Date(
                          selectDate.year,
                          selectDate.month - 1,
                          selectDate.day
                        )
                      );
                    }
                  }}
                />
                <CalendarExtraContent>
                  <DurationLabel>Select:</DurationLabel>
                  <TimePickerContainer>
                    <StyledTimePicker
                      defaultTime={selectedDate}
                      onChange={(time) => {
                        if (!time) {
                          return;
                        }
                        const inDateFormat = time.toDate();
                        setSelectedTimeBackup(inDateFormat);
                      }}
                    />
                    <TimeLabel>to</TimeLabel>
                    <StyledTimePicker
                      defaultTime={endTime}
                      onChange={(time) => {
                        if (!time) {
                          return;
                        }
                        const inDateFormat = time.toDate();
                        setSelectedEndTimeBackup(inDateFormat);
                      }}
                    />
                  </TimePickerContainer>
                  <Button
                    onClick={() => {
                      if (
                        selectedTimeBackup.getTime() <=
                        selectedEndTimeBackup.getTime()
                      ) {
                        const newSelectedDate = moment(selectedDateBackup)
                          .set({
                            hour: selectedTimeBackup.getHours(),
                            minute: selectedTimeBackup.getMinutes(),
                          })
                          .toDate();
                        const newSelectedEndDate = moment(selectedDateBackup)
                          .set({
                            hour: selectedEndTimeBackup.getHours(),
                            minute: selectedEndTimeBackup.getMinutes(),
                          })
                          .toDate();
                        onDateChange &&
                          onDateChange(newSelectedDate, newSelectedEndDate);
                        setVisible(false);
                      } else {
                        alert("Start time must be before end time");
                      }
                    }}
                    fullWidth
                  >
                    Apply
                  </Button>
                </CalendarExtraContent>
              </DurationPickerContainer>
            )}
          </HiddenContent>
        </DateButtonWrapper>
      </ButtonGroup>
      <IconButtonContainer>
        <Button
          type="secondary-c"
          isIconButton={width > size.sm}
          fullWidth={width <= size.sm}
          disabled={
            mode === "duration" &&
            selectedDate.getDate() === minCalendar.getDate()
          }
          onClick={() => {
            const prevDate = new Date(selectedDate.getTime());
            if (mode === "date" || mode === "duration") {
              prevDate.setDate(prevDate.getDate() - 1);
            } else if (mode === "month") {
              prevDate.setMonth(prevDate.getMonth() - 1);
            } else if (mode === "week") {
              prevDate.setDate(prevDate.getDate() - 7);
            }
            if (mode === "duration") {
              const prevEndDate = moment(endTime).subtract(1, "d").toDate();
              onDateChange && onDateChange(prevDate, prevEndDate);
            } else {
              onDateChange && onDateChange(prevDate);
            }
          }}
        >
          <IconWrapper>
            <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            {width <= size.sm && (
              <span>
                {`Previous ${
                  mode === "date" || mode === "duration"
                    ? "Day"
                    : mode === "week"
                    ? "Week"
                    : "Month"
                }`}
              </span>
            )}
          </IconWrapper>
        </Button>
        <Button
          type="secondary-c"
          isIconButton={width > size.sm}
          fullWidth={width <= size.sm}
          disabled={checkDisabledState()}
          onClick={() => {
            const nextDate = new Date(selectedDate.getTime());
            if (mode === "date" || mode === "duration") {
              nextDate.setDate(nextDate.getDate() + 1);
            } else if (mode === "month") {
              nextDate.setMonth(nextDate.getMonth() + 1);
            } else if (mode === "week") {
              nextDate.setDate(nextDate.getDate() + 7);
            }
            if (mode === "duration") {
              const nextEndDate = moment(endTime).add(1, "d").toDate();
              onDateChange && onDateChange(nextDate, nextEndDate);
            } else {
              onDateChange && onDateChange(nextDate);
            }
          }}
        >
          <IconWrapper>
            {width <= size.sm &&
              `Next ${
                mode === "date" || mode === "duration"
                  ? "Day"
                  : mode === "week"
                  ? "Week"
                  : "Month"
              }`}
            <FontAwesomeIcon icon={["fas", "chevron-right"]} />
          </IconWrapper>
        </Button>
      </IconButtonContainer>
    </ButtonContainer>
  );
};
