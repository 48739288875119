import styled, { css } from "styled-components";
import { Link } from "../../components";
import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  > :not(:last-child) {
    margin-right: 4px;
  }
  @media ${maxLayout.lg} {
    display: none;
  }
`;

export const Tab = styled(Link)<{ isActive?: boolean }>`
  padding: 12px 20px;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.stream.grey7};
  background: ${(props) => props.theme.colors.stream.overlay};
  :hover {
    background: ${(props) => props.theme.colors.stream.overlayHover};
  }
  ${(props) => {
    if (props.isActive) {
      return css`
        color: ${props.theme.colors.stream.navy};
        background: ${props.theme.colors.stream.lightBlue};
        :hover {
          background: ${props.theme.colors.stream.lightBlue};
        }
      `;
    }
  }}
`;
