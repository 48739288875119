import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import theme from "../../theme/theme";
import { formatNumber } from "../../utils/formatNumber/formatNumber";
import {
  ModalOverlay,
  ModalCard,
  ModalHeaderWrapper,
  CloseIcon,
  ModalTitle,
  ModalContentTitle,
  ModalContentText,
  ModalContentValue,
  GridTwoContainer,
  GridThreeLabelContainer,
  GridThreeValueContainer,
  GridExtraContainer,
  ModalContentSection,
} from "./SchedulesModal.style";
import WorldMapChart from "../worldMapChart/WorldMapChart";
import { DonutChart } from "../donutChart/DonutChart";
import { AnalyticsTable } from "../analyticsTable/AnalyticsTable";

export interface ModalData {
  programTitle: string;
  episodeName: string;
  startTime: string;
  endTime: string;
  subscribers: number;
  maxConcurrents: number;
  watchDurationMinutes: number;
  totalViewers: number;
  totalViewersByCountry: { label: string; value: number }[];
  totalViewersByDevice: { label: string; value: number }[];
  totalViewersByOs: { label: string; value: number }[];
}

interface ModalProps {
  data?: ModalData;
  visible: boolean;
  loading: boolean;
  onClose?: () => void;
}

export const SchedulesModal = (props: ModalProps) => {
  const { data, visible, loading, onClose } = props;

  useEffect(() => {
    if (visible) {
      document.body.style.height =
        `${(
          (document.getElementById("schedules-modal")?.offsetHeight as number) +
          32
        ).toString()}px` || "auto";
    } else {
      document.body.style.height = "unset";
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      document.body.style.height =
        `${(
          (document.getElementById("schedules-modal")?.offsetHeight as number) +
          32
        ).toString()}px` || "auto";
    } else {
      document.body.style.height = "unset";
    }
  }, [visible]);

  if (!visible || data === undefined) {
    return null;
  }

  return (
    <>
      <ModalOverlay onClick={onClose} />
      <ModalCard id="schedules-modal">
        <ModalHeaderWrapper>
          <ModalTitle>{data.programTitle}</ModalTitle>
          <CloseIcon icon={["fas", "times"]} size="lg" onClick={onClose} />
        </ModalHeaderWrapper>
        <GridExtraContainer>
          <ModalContentSection>
            <ModalContentTitle>Time</ModalContentTitle>
            <ModalContentText>
              {loading ? (
                <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
              ) : (
                `${moment(data.startTime).format("HH:mm")} - ${moment(
                  data.endTime
                ).format("HH:mm")} น.`
              )}
            </ModalContentText>
          </ModalContentSection>
          <ModalContentSection>
            <ModalContentTitle>Episode Name</ModalContentTitle>
            <ModalContentText>
              {loading ? (
                <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
              ) : data.episodeName && data.episodeName !== "" ? (
                data.episodeName
              ) : (
                "-"
              )}
            </ModalContentText>
          </ModalContentSection>
        </GridExtraContainer>
        <GridThreeLabelContainer>
          <ModalContentSection>
            <ModalContentTitle>Watch Duration Minutes</ModalContentTitle>
          </ModalContentSection>
          <ModalContentSection>
            <ModalContentTitle>Max Concurrents</ModalContentTitle>
          </ModalContentSection>
          <ModalContentSection>
            <ModalContentTitle>Subscribers</ModalContentTitle>
          </ModalContentSection>
        </GridThreeLabelContainer>
        <GridThreeValueContainer>
          <ModalContentSection>
            <ModalContentValue>
              {loading ? (
                <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
              ) : (
                formatNumber(data.watchDurationMinutes)
              )}
            </ModalContentValue>
          </ModalContentSection>
          <ModalContentSection>
            <ModalContentValue>
              {loading ? (
                <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
              ) : (
                formatNumber(data.maxConcurrents)
              )}
            </ModalContentValue>
          </ModalContentSection>
          <ModalContentSection>
            <ModalContentValue>
              {loading ? (
                <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
              ) : (
                formatNumber(data.subscribers)
              )}
            </ModalContentValue>
          </ModalContentSection>
        </GridThreeValueContainer>
        <ModalContentTitle line>Country</ModalContentTitle>
        <GridTwoContainer>
          <WorldMapChart
            data={data.totalViewersByCountry.map((country) => {
              return { country: country.label, value: country.value };
            })}
          />
          <AnalyticsTable
            data={
              data?.totalViewersByCountry ? data?.totalViewersByCountry : []
            }
            category="Country"
            loading={loading}
            ratio={62.5}
          />
        </GridTwoContainer>
        <GridTwoContainer>
          <ModalContentSection>
            <ModalContentTitle line>Device</ModalContentTitle>
            <DonutChart
              categories={
                data.totalViewersByDevice
                  ? data.totalViewersByDevice.map((device) => device.label)
                  : []
              }
              data={
                data?.totalViewersByDevice
                  ? data.totalViewersByDevice.map((device) => device.value)
                  : []
              }
              loading={loading}
            />
            <AnalyticsTable
              totalValue={data.totalViewers}
              colors={Object.values(theme.colors.graph)}
              data={
                data?.totalViewersByDevice ? data?.totalViewersByDevice : []
              }
              category="Device"
              loading={loading}
            />
          </ModalContentSection>
          <ModalContentSection>
            <ModalContentTitle line>OS</ModalContentTitle>
            <DonutChart
              categories={
                data.totalViewersByOs
                  ? data.totalViewersByOs.map((os) => os.label)
                  : []
              }
              data={
                data?.totalViewersByOs
                  ? data.totalViewersByOs.map((os) => os.value)
                  : []
              }
              loading={loading}
            />
            <AnalyticsTable
              totalValue={data.totalViewers}
              colors={Object.values(theme.colors.graph)}
              data={data?.totalViewersByOs ? data?.totalViewersByOs : []}
              category="OS"
              loading={loading}
            />
          </ModalContentSection>
        </GridTwoContainer>
      </ModalCard>
    </>
  );
};
