import styled, { css } from "styled-components";

import { maxLayout, minLayout } from "../../utils/breakpoint/breakpoint";

export const RatioWrapper = styled.div<{ height?: string; ratio?: string }>`
  ${(props) => {
    if (!props.height) {
      if (props.ratio) {
        return css`
          padding-top: ${props.ratio};
          position: relative;
        `;
      } else {
        return css`
          max-height: inherit;
        `;
      }
    } else {
      return css`
        height: ${props.height};
      `;
    }
  }}
  @media ${maxLayout.sm} {
    height: fit-content;
    padding-top: 0;
  }
`;

export const TableWrapper = styled.div<{ height?: string; ratio?: string }>`
  max-height: 100%;
  overflow-y: auto;
  ${(props) => {
    if (!props.height) {
      if (props.ratio) {
        return css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `;
      } else {
        return css`
          max-height: inherit;
        `;
      }
    }
  }}

  @media ${maxLayout.sm} {
    max-height: fit-content;
    position: static;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
`;

export const StyledTableHead = styled.thead`
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.colors.stream.white};
  z-index: 5;
`;

export const KeyHeader = styled.td`
  margin: 0;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.stream.grey1};
`;

export const ViewHeader = styled.td`
  margin: 0;
  max-width: 65px;
  width: 65px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: ${(props) => props.theme.colors.stream.grey1};
  border-left: 2px solid ${(props) => props.theme.colors.stream.white};

  @media ${maxLayout.md} {
    width: 52px;
  }
`;

export const TableContent = styled.td<{ isAlignRight?: boolean }>`
  margin: 0;
  padding: 7px 16px;
  font-size: 14px;
  text-align: ${(props) => (props.isAlignRight ? "right" : "left")};
  color: ${(props) => props.theme.colors.stream.grey1};
  :not(:first-child) {
    border-left: 4px solid ${(props) => props.theme.colors.stream.white};
  }
`;

export const TableRow = styled.tr<{ color: string }>`
  border-left: 24px solid ${(props) => props.color};
  background: ${(props) => props.theme.colors.stream.grey7};
  :nth-child(2n) {
    background: ${(props) => props.theme.colors.stream.grey9};
  }

  @media ${maxLayout.sm} {
    border-left-width: 16px;
  }
`;

export const NoDataCard = styled.div<{ height?: string; ratio?: string }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.stream.grey2};
  max-height: 100%;
  overflow-y: auto;
  ${(props) => {
    if (!props.height) {
      if (props.ratio) {
        return css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        `;
      } else {
        return css`
          max-height: inherit;
        `;
      }
    }
  }}

  @media ${maxLayout.sm} {
    max-height: fit-content;
    position: static;
  }
`;

export const NoDataLabel = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 16px;
  text-align: center;
`;

export const SeeMoreButton = styled.div`
  display: block;
  text-align: center;
  padding: 4px;
  border-radius: 0px 0px 3px 3px;
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.stream.grey4};
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.stream.grey3};
    border: 1px solid ${(props) => props.theme.colors.stream.grey3};
  }

  @media ${minLayout.sm} {
    display: none;
  }
`;
