import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "flag-icon-css/css/flag-icon.css";
import "rc-time-picker/assets/index.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

import Router from "./router";
import theme from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

library.add(fas, far, fal, fad, fab);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
