import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getIconOS = (osName: string) => {
  const lowerCaseOsName = osName.toLowerCase();

  if (lowerCaseOsName === "unknown") {
    return <FontAwesomeIcon icon={["fas", "question-circle"]} />;
  }

  if (lowerCaseOsName.includes("windows")) {
    return <FontAwesomeIcon icon={["fab", "windows"]} />;
  } else if (
    lowerCaseOsName.includes("mac os x") ||
    lowerCaseOsName.includes("ios")
  ) {
    return <FontAwesomeIcon icon={["fab", "apple"]} />;
  } else if (lowerCaseOsName.includes("linux")) {
    return <FontAwesomeIcon icon={["fab", "linux"]} />;
  } else if (lowerCaseOsName.includes("android")) {
    return <FontAwesomeIcon icon={["fab", "android"]} />;
  } else if (lowerCaseOsName.includes("other")) {
    return <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />;
  } else {
    return <FontAwesomeIcon icon={["fas", "minus"]} />;
  }
};
