import axios from "axios";

export const getApiConfig = (teamName?: string, didExport?: boolean) => {
  if (!!teamName) {
    return {
      headers: {
        "x-byteark-team": teamName,
        "Access-Control-Allow-Credentials": true,
      },
    };
  }
  if (didExport) {
    return {
      headers: {
        "Access-Control-Allow-Credentials": true,
      },
      responseType: "blob" as any,
    };
  }
  return {
    headers: {
      "Access-Control-Allow-Credentials": true,
    },
  };
};

export const getAxiosInstance = (
  type: "default" | "logout",
  domain?: string
) => {
  const currentDomain = domain ? domain : window.location.origin;
  if (type === "logout") {
    return axios.create({
      baseURL: `${currentDomain}/`,
    });
  } else {
    return axios.create({
      baseURL: `${currentDomain}/api/usage/v1/`,
    });
  }
};
