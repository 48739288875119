import { ReactNode } from "react";

import { IconWrapper, IconLabel } from "./Icon.style";

interface IconProps {
  /**
   * Icon component
   */
  icon: ReactNode;
  /**
   * Icon text label
   */
  label?: string;
  /**
   * Icon's color
   */
  color?: string;
}

export const Icon = (props: IconProps) => {
  const { icon, label, color } = props;

  return (
    <IconWrapper color={color}>
      <>{icon} </>
      {label && <IconLabel>{label}</IconLabel>}
    </IconWrapper>
  );
};
