import styled from "styled-components";

import { maxLayout } from "../../utils/breakpoint/breakpoint";

export const ContentCard = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
`;

export const ContentHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;

  @media ${maxLayout.md} {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 12px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  > :not(:first-child) {
    margin-left: 9px;
  }

  @media ${maxLayout.md} {
    width: 100%;
    padding-top: 12px;
    border-top: 1px solid ${(props) => props.theme.colors.stream.grey6};
  }
`;

export const DatePickerWrapper = styled.div`
  width: 147px;

  @media ${maxLayout.md} {
    width: 100%;
    flex: 1;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.stream.grey1};

  @media ${maxLayout.md} {
    margin-bottom: 12px;
  }
`;
